/**
 * the tables in the database
 */
export enum DatabaseTable {
  PRE_EMPLOYMENT_ASSESSMENTS = 'pre_employment_assessments',
  INTAKE_RESPONSES = 'intake_responses',
  EMPLOYMENT_READINESS_RESPONSES = 'employment_readiness_responses',
  SKILLS_RESPONSES = 'skills_responses',
  CAREER_READINESS_RESPONSES = 'career_readiness_responses',
  EXPERIENCE_RESPONSES = 'experience_responses',
  SWOT_REPORTS = 'swot_reports',
  ORGANIZATIONS = 'organizations',
  NEWSLETTER_SIGNUPS = 'newsletter_signups',
  USER_COURSE_PROGRESS = 'user_course_progresses',
  USER_PROFILE = 'user_profile',
  COURSE_QUESTION_RESPONSE = 'course_question_response',
  CONTACT_US_RESPONSES = 'contact_us_responses',
  COURSE_PRE_POST_ASSESMENT_RESPONSE = 'course_assessment_responses',
  ASA_TASk_HISTORY_RESPONSES = 'asa_task_history_responses',
  USER_TRANSACTION_HISTORY = 'transaction_history',
  USER_ORDERS = 'user_orders',
  USER_ENGAGEMENT = 'user_engagement',
  USER_DOCUMENTS = 'user_documents',
  ORGANIZATION_SUBSCRIPTIONS = 'organization_subscriptions',
}
