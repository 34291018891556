/* eslint-disable @typescript-eslint/no-misused-promises */
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { Button, Container } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import avatar from '../assets/images/Muser.jpg';
import { OrgAdminAuthenticatedPage } from '../components/AuthenticatedPage';
import { GenericDataTable } from '../components/common/GenericDataTable';
import { ProfileCard } from '../components/Dashboard/ProfileCard';
import { CircularLoader } from '../components/loader/CircularLoader';
import UserDetailsModal from '../components/Popup/UserDetailsPopup'; // Import the UserDetailsModal component
import { useAuthContext } from '../hooks/use-user-auth-context.hooks';
import { useUserProfileContext } from '../hooks/user-user-profile-context.hooks';
import { CustomUser } from '../redux/slice/auth/CustomUser';
import {
  SubscriptionData,
  SubscriptionUsersData,
} from '../redux/slice/organization-dashboard/organization.types';
import {
  dispatchGetSubscriptions,
  dispatchGetUsers,
  dispatchUpsertNewSubscription,
} from '../redux/slice/organization-subscriptions/organization-subscriptions-operations';
import { TransactionMetaData } from '../redux/slice/organization-subscriptions/organization-subscriptions-types';
import { dispatchGetUserProfileValues } from '../redux/slice/user-profile';
import { useAppDispatch } from '../redux/store';
import { createOrganizationPaymentSession } from '../services/stripe/stripe';
import { generateSubscriptionId } from '../utils/helper';

const OrganizationUpgrade: React.FC = () => {
  const [data, setData] = useState<SubscriptionData[]>([]);
  const [userCount, setUserCount] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<SubscriptionUsersData[]>([]);
  const userProfileContext = useUserProfileContext();
  const [errorMessage, setErrorMessage] = useState('');
  const [isPaymentProcessing, setIsPaymentProcessing] = useState({
    addUsers: false,
    renew: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const user: CustomUser | null = useAuthContext();

  useEffect(() => {
    const getUsersData = async () => {
      const subsData =
        user?.claims?.organizationId &&
        (await dispatchGetSubscriptions(user?.claims?.organizationId));
      subsData && setData(subsData);
    };
    void getUsersData();
  }, []);
  // Renew subscription for a single user
  // const handleIndividualRenew = async (user: SubscriptionUsersData) => {
  //   try {
  //     toast.success(`Subscription renewed for ${user.name}`);
  //   } catch (error) {
  //     toast.error('Something went wrong...');
  //   }
  // };

  const userColumns = [
    // { id: 'name', label: 'Subscription Id', accessor: 'subscriptionId' },
    { label: 'Purchase Date', accessor: 'createdAt' },
    { label: 'Expiry Date', accessor: 'endDate' },
    { label: 'Status', accessor: 'status' },

    { label: 'Amount ($)', accessor: 'amount' },
    {
      id: 'UserCount',
      label: 'Number Of Users',
      accessor: 'numberOfUsers',
      Cell: (row: SubscriptionData) => (
        <Button onClick={() => openUserModal(row)}>{row.numberOfUsers}</Button>
      ),
    },
    {
      label: 'Renew Subscription',
      accessor: 'action',
      Cell: (row: SubscriptionData) => {
        const isExpired = dayjs(row.endDate).isBefore(dayjs());
        return (
          <button
            onClick={() => handleRenewAll(row)}
            disabled={!isExpired}
            className="cursor-pointer bg-purple-500 disabled:bg-purple-300  text-white font-semibold py-3 px-6 rounded-full  hover:shadow-lg  mt-5 mx-auto"
          >
            Renew
          </button>
        );
      },
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    void dispatch(dispatchGetUserProfileValues());
  }, []);

  const filteredData = data.sort((a, b) => {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();
    return dateB - dateA;
  });
  const handleRenewAll = async (subscription: SubscriptionData) => {
    try {
      setIsPaymentProcessing({ ...isPaymentProcessing, renew: true });
      if (user) {
        const subscriptionId = generateSubscriptionId();

        const paymentSessionData = (await createOrganizationPaymentSession(
          user,
          subscription.numberOfUsers,
          subscriptionId,
        )) as {
          url: string;
          sessionId: string;
        } | null;
        if (paymentSessionData) {
          location.href = paymentSessionData.url;
        } else {
          toast.error('Something went wrong, Please try after some time.');
        }
      }
    } catch (error) {
      toast.error('Something went wrong...');
    } finally {
      setIsPaymentProcessing({ ...isPaymentProcessing, renew: false });
    }
  };

  const upgradeToPro = async () => {
    setIsPaymentProcessing({ ...isPaymentProcessing, addUsers: true });
    if (user) {
      const subscriptionId = generateSubscriptionId();

      const paymentSessionData = (await createOrganizationPaymentSession(
        user,
        userCount,
        subscriptionId,
      )) as {
        url: string;
        sessionId: string;
        metaData: TransactionMetaData;
        amount: number;
      } | null;
      if (paymentSessionData) {
        user?.claims?.organizationId &&
          (await dispatchUpsertNewSubscription(
            user?.claims?.organizationId,
            paymentSessionData?.metaData,
            paymentSessionData?.sessionId,
            paymentSessionData?.amount,
            subscriptionId,
          ));
        location.href = paymentSessionData.url;
      } else {
        toast.error('Something went wrong, Please try after some time.');
      }
    }
  };

  const handleIncrement = () => {
    if (userCount < 9999) {
      setUserCount((prevCount) => prevCount + 1);
      setErrorMessage(''); // Clear error message if valid
    } else {
      setErrorMessage('You can only buy up to 9999 subscriptions at a time.');
    }
  };

  const handleDecrement = () => {
    if (userCount > 0) {
      setUserCount((prevCount) => prevCount - 1);
      setErrorMessage(''); // Clear error message if valid
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Allow only digits and an empty string (to clear the input)
    if (/^\d*$/.test(value)) {
      const numericValue = Math.min(9999, Math.max(0, Number(value))); // Enforce 0–9999 range
      setUserCount(numericValue);
      setErrorMessage(
        numericValue >= 9999 ? 'You can only buy up to 9999 subscriptions at a time.' : '',
      );
    }
  };

  const openUserModal = async (users: SubscriptionData) => {
    setIsLoading(true);

    try {
      if (user && user?.claims && user?.claims?.organizationId) {
        const usersData = await dispatchGetUsers(user?.claims.organizationId, users.subscriptionId);
        if (usersData) {
          setSelectedUsers(usersData);
        } else {
          setSelectedUsers([]);
        }
      }
      setIsModalOpen(true);
    } catch (error) {
      toast.error('Something went wrong');
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const closeUserModal = () => {
    setIsModalOpen(false);
    setSelectedUsers([]);
  };

  return (
    <div className="dashboard">
      {isPaymentProcessing.addUsers && <CircularLoader />}
      {isPaymentProcessing.renew && <CircularLoader />}
      {isLoading && <CircularLoader />}

      <Container>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5">
          <ProfileCard
            heading="PERSONAL INFORMATION"
            buttonText="No Button Here"
            buttonIcon={<AddCircle />}
            isVisible={false}
          >
            <div className="flex flex-col items-center justify-center text-center ">
              <img
                className="h-20 md:h-36 w-20 md:w-36 object-cover rounded-full mb-3"
                src={userProfileContext?.profileImagePath || avatar}
                alt=""
              />
              <div className="mt-2">
                <p className="text-lg break-all font-bold mb-1">{userProfileContext?.name}</p>
                <p className="text-sm break-all">{userProfileContext?.email}</p>
              </div>
            </div>
          </ProfileCard>
          <ProfileCard
            heading="Buy Subscription"
            buttonText="No Button Here"
            buttonIcon={<AddCircle />}
            isVisible={false}
          >
            <div className="text-center my-5 p-6 rounded-lg shadow-md border ">
              <div className="mb-6">
                <label className="text-lg font-semibold text-gray-700">
                  Select Number of Users
                </label>
                <div className="grid grid-cols-3 items-center justify-items-center mt-4 gap-4">
                  <button
                    onClick={handleDecrement}
                    disabled={userCount <= 0}
                    className="rounded-full p-2 transition-all duration-200 bg-purple-100 hover:bg-purple-300 text-purple-600 disabled:text-gray-300 disabled:bg-gray-100"
                  >
                    <RemoveCircle fontSize="large" className="text-4xl" />
                  </button>

                  <input
                    type="number"
                    min="0"
                    max="9999"
                    value={userCount}
                    onChange={handleInputChange}
                    className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none text-center text-lg font-semibold text-purple-700 border border-gray-300 rounded p-1 w-20"
                  />

                  <button
                    onClick={handleIncrement}
                    className="rounded-full p-2 transition-all duration-200 bg-purple-100 hover:bg-purple-300 text-purple-600"
                  >
                    <AddCircle fontSize="large" className="text-4xl" />
                  </button>
                </div>
                {errorMessage && <p className="text-red-600 text-sm mt-2">{errorMessage}</p>}
              </div>

              <button
                disabled={userCount === 0}
                onClick={upgradeToPro}
                className="cursor-pointer bg-purple-500 disabled:bg-purple-300 text-white font-semibold py-3 px-6 rounded-full hover:shadow-lg mt-5 mx-auto"
              >
                Buy Subscriptions
              </button>
            </div>
          </ProfileCard>
        </div>
        <ProfileCard
          heading="My Subscriptions"
          buttonText="No Button Here"
          buttonIcon={<AddCircle />}
          isVisible={false}
        >
          <div className="text-center my-5">
            <GenericDataTable
              isLoading={false}
              data={filteredData}
              ctaLoader={false}
              filterFields={[
                { name: 'name', label: 'Filter by Name' },
                { name: 'email', label: 'Filter by Email' },
              ]}
              isCourseTable={false}
              columns={userColumns}
              isFilterRequired={false}
            />
          </div>
        </ProfileCard>

        {/* User Details Modal */}
        <UserDetailsModal
          isOpen={isModalOpen}
          onClose={closeUserModal}
          users={selectedUsers}
          // onRenew={handleIndividualRenew} // Pass the individual renew function
        />
      </Container>
    </div>
  );
};

export const OrganizationUpgradedPage: React.FC = () => {
  return <OrgAdminAuthenticatedPage render={() => <OrganizationUpgrade />} />;
};
