import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Footer } from './Footer';
import { Header } from './Header';

const Layout: React.FC = () => {
  return (
    <>
      <div className="min-h-screen">
        <ToastContainer theme="colored" limit={1} autoClose={2000} position="top-center" />
        <div className="w-full block">
          <Header />
          <main>
            <Outlet />
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
