import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import sideImg from '../../../../assets/images/courses/communication-basics/chapter10/sideImg.jpeg';
import {
  course_questions as courseQuestions,
  course_sorting_questions as sortingQuestions,
} from '../../../../courses/communication-basics/communication-basics.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseQuestionAnswer,
  QuestionOption,
  ShortingMatchingBase,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  dispatchUpdateUserCourseQuestionResponse,
  UserChapterQuestion,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CustomAccordion } from '../../../common/Accordion';
import { CourseQuestionCard } from '../../CourseQuestionCard';
import { CourseSortingQuestionBlock } from '../../SortingQuestion/CourseSortingQuestion';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};
type SortingAnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};
export const CommunicationBasicsChapter10: React.FC<ChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);

  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const [, setAnswers] = useState<AnswersState>({});
  const chapterQuestions = courseQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const chapterSortingQuestions = sortingQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const accordionData = [
    {
      id: 'panel1',
      title: 'Relationship',
      content:
        '<div class="fr-view"><p>Your relationship with your reader refers to how well you know this person.</p><p><br></p><p>Before you start writing, think about what your reader might expect you to say depending on that relationship. Is this relationship formal and professional or relaxed and casual? Are you writing to a new acquaintance or a close friend?</p></div>',
    },
    {
      id: 'panel2',
      title: 'Knowledge',
      content:
        '<div class="fr-view"><p>Your reader’s knowledge refers to how much they already know about the subject you’re covering.</p><p><br></p><p>Before you can decide what to say, it’s important to think about what your reader already understands. Is this material new to them, or are they already an expert?</p></div>',
    },
    {
      id: 'panel3',
      title: 'Perspective',
      content:
        '<div class="fr-view"><p>Your reader’s perspective refers to their viewpoints.</p><p>&nbsp;</p><p>When considering your audience’s perspective, try putting yourself in their shoes and asking a few questions: <em>What matters most to my reader? What interests them? Do they already have an opinion on this topic?</em></p></div>',
    },
  ];
  const WritingYourAudienceaccordionData = [
    {
      id: 'panel1',
      title: 'Relationship',
      content:
        '<div class="fr-view"><p>Depending on the relationship you have with your reader, you’ll likely need to adjust your tone. Your tone refers to how you phrase and style your message.</p><p>&nbsp;</p><p>If your relationship is professional or new, it’s best to also use a <strong>professional tone</strong> as a way of showing respect. That means using full words, clearly explaining your points, and remaining objective. Consider how the message in the introduction used a formal tone when writing to your boss. The writing was polite, detailed, and unemotional.</p><p>&nbsp;</p><p>If you’re writing to a close friend or a familiar colleague, however, it’s best to keep your <strong>tone informal</strong>. That means you’re free to use exclamation points, ellipses (…), contractions, and even slang and figures of speech. Think about how a friend might misinterpret your message if you used the wrong tone. An informal text that says “<em>Ya, it’s totally fine!”</em> is seen as friendly, whereas a professional text that says <em>“Yes. It is fine.”</em> might come across as distant.</p></div>',
    },
    {
      id: 'panel2',
      title: 'Perspective',
      content:
        '<div class="fr-view"><p>Depending on your reader’s perspective, you’ll likely need to change your <strong>content</strong>. Your content refers to the information and details you include in your writing.</p><p>&nbsp;</p><p>If your reader already has a strong viewpoint on the topic, whether it’s positive or negative, it’s best to address this in your content.</p><p><br></p><p>For example, let’s say you’re writing a proposal to your boss on why employees should be allowed to work from home. In this instance, you know your boss has a negative perspective on the topic because she assumes staff will be less productive. Knowing this, you could appeal to your boss’s perspective by focusing on what matters most to her. For example, you could include content that explains why working from home limits distractions, leads to fewer unnecessary conversations, and helps employees get more done in less time.</p></div>',
    },
    {
      id: 'panel3',
      title: 'Knowledge',
      content:
        '<div class="fr-view"><p>Depending on what your reader already knows, you’ll want to adjust both the <strong>content</strong> you include and the type of <strong>language</strong> you use in your writing.</p><p>&nbsp;</p><p>If this material is new to your reader, it’s a good idea to provide more content and background information. If your reader is already an expert, however, this information isn’t necessary and can even be received negatively—as though you’re talking down to them. For example, think about how you might explain a legal document to a lawyer differently than you would to a friend with no background in the subject.</p><p>&nbsp;</p><p>When considering a reader’s knowledge level, also think about how you might use different <strong>language</strong>—or vocabulary terms specific to the topic. If your reader is new to the subject, they’d likely have trouble understanding overly technical vocabulary or acronyms. If your reader is already an expert, on the other hand, you’ll want to meet them at their level so that your writing is meaningful and useful to them.</p></div>',
    },
  ];
  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              sortingQuestions: [...userSortingAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];
    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              quiz: null,
              sortingQuestions: [...userSortingAnswerResponse],
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleSortingExcerciseCompleted = async (sortingAnswers: SortingAnswersState) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];
    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterSortingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(Object.keys(sortingAnswers)[0]),
                ),
                sortingAnswers,
              ],
              questions: [...userAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleSortingRetake = async (questionId: string) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];
    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterSortingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(questionId),
                ),
              ],
              questions: [...userAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  useEffect(() => {
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions;
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions;
    if (
      Object.keys(userCourseQuestionResponseContext).length &&
      userAnswerResponse?.length &&
      userSortingAnswerResponse?.length
    ) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);

  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">Writing for Your Audience</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <p className="text-xl mt-16 font-light mb-16">
          Imagine you’re stuck at home with the flu. Your nose is running, your head is throbbing,
          and there’s no way you’re going to make it into work. You need to notify your boss, but
          how do you phrase that email? It could look something like this:
          <br />
          <br />
          To your boss: “I am writing to let you know that I cannot make it into the office today
          due to illness. I apologize for any inconvenience."
        </p>
        <p>
          Now, what if you wanted to share this same information with a close coworker? Given your
          relationship, that original message may come off as cold and impersonal. Instead, you
          might rephrase it:
          <br />
          <br />
          <span className="font-bold">To your coworker:</span> “Ugh! Bad news! I’ve got the worst
          flu and can’t come in!
        </p>
        <p className="text-2xl text-center leading-10 mt-16 font-light mb-16">
          In short, even if the subject is the same, we often change what we write and how we write
          it depending on our readers. We think about our relationship with them, we look at the
          situation from their perspective, and we consider what they might already know about the
          topic. Keeping our audience in mind, we then tailor our content, our language, and our
          tone to fit their needs so that they can better understand and positively react to our
          messages.
        </p>
        <p className="mb-8">
          In this lesson, we’ll explain the benefit of writing for your audience and how you can use
          this to your advantage. We’ll take a look at how to analyze your readers by considering
          their relationship with you, their perspective, and their knowledge. We’ll then unpack how
          to change your content, your language, and your tone, depending on that reader.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls controlsList="nodownload">
            <source
              src={chapter?.course_video_url && chapter?.course_video_url[0]}
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          The Importance of Writing for Your Audience
        </h3>
        <p className="mb-8">
          Whether you’re writing to a new employee, an industry expert, or a close colleague, you’ll
          likely encounter many different types of readers throughout your professional career. To
          help make sure these readers understand your messages and react positively to them, you’ll
          need to write with the receiver in mind. For example, let’s say you’re writing
          instructions on how to use social media. If your reader is new to the topic, you’ll likely
          need to include extra details and avoid common buzzwords such as “gif,” “meme,” and
          “wall.”
        </p>
        <div className="md:flex items-center">
          <img className="w-96 mr-7 mb-5 md:mb-0" src={sideImg} alt="" />
          <div>
            But what if you ignored that reader? What if instead you wrote a technologically savvy
            document containing jargon and covering advanced topics? Most likely, your reader won’t
            understand the writing, and you’ll have lost an opportunity to communicate your message
            successfully.
            <br />
            <br />
            By being mindful of your audience, it’s more likely your content will be useful to them,
            they’ll understand the information, and they’ll react in the way you’re hoping.
          </div>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          Understanding Your Audience
        </h3>
        <p className="mb-8">
          When trying to understand your audience, there are three things you should consider: their
          relationship with you, their perspective on the topic you’re covering, and how much they
          already know about the subject.
          <br />
          <br />
          Check below to learn more about how to analyze these three factors.
        </p>
        {accordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">Writing for Your Audience</h3>
        <p className="mb-5">
          By keeping your audience in mind, you can more easily adjust your writing to fit their
          needs. Expand the rows below by clicking the (+) signs to see how you might need to change
          what you say and how you say it, depending on your reader’s relationship with you, as well
          as their perspective and knowledge of the topic you’re covering.
        </p>
        {WritingYourAudienceaccordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">Test Your Skills</h3>
        <p className="mb-5">
          Considering what you’ve learned, can you match each of the statements below with their
          corresponding cards? When selecting your answers, remember to think about the definitions
          of these terms. How would your audience influence the type of information you include, the
          vocabulary you use, and the voice you take on?
        </p>
        <DndProvider backend={HTML5Backend}>
          <CourseSortingQuestionBlock // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetake={handleSortingRetake}
            question={chapterSortingQuestions[0]}
            submitedCorrectAnswerCount={
              userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[
                chapter.id
              ]?.sortingQuestions
                ?.filter((x) =>
                  Object.keys(x).filter((key) => key === chapterSortingQuestions[0].id),
                )
                .map(
                  (x) =>
                    x[chapterSortingQuestions[0].id].filter(
                      (x) => x.answer?.id === x.correct_answer?.id,
                    ).length,
                )[0] ?? null
            }
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            submitSortingQuestion={handleSortingExcerciseCompleted}
          />
        </DndProvider>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          How Will You Phrase This Message?
        </h3>
        <CourseQuestionCard
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetakeQuestion={handleQuestionRetake}
          submittedAnswer={userCourseQuestionResponseContext[
            chapterQuestions[0].course_id
          ]?.chapters[chapter.id]?.questions?.at(0)}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmitAnswer={handleCourseAnswerSubmit}
          question={chapterQuestions[0]}
        />
        <p className="text-2xl text-center leading-10 font-light mb-10">
          Once you know the purpose of your message, it's time to choose the right medium to
          communicate that message. Continue on to the next lesson for some tips on how to do that.
        </p>

        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full"
        >
          HOW TO STRUCTURE YOUR WRITING
        </button>
      </div>
    </>
  );
};
