import { AsaTaskName } from '../redux/slice/asa-task-history';

export const allowOnlyNumbers = (event: React.KeyboardEvent<HTMLInputElement>): void => {
  const isNumberKey = /^\d$/.test(event.key);
  if (!isNumberKey) {
    event.preventDefault();
  }
};
export const handlePasteOnlyNumbers = (event: React.ClipboardEvent<HTMLInputElement>): void => {
  const clipboardData = event.clipboardData;
  const pastedText = clipboardData.getData('text');
  if (!/^\d+$/.test(pastedText)) {
    event.preventDefault();
  }
};

export const isASATaskRequest = (queryParams: URLSearchParams): boolean => {
  return (
    !!queryParams.get('user_id') &&
    !!queryParams.get('unique_task_key') &&
    !!queryParams.get('step_number')
  );
};
export const buildASANextTaskUrl = (
  url: URL,
  taskName: AsaTaskName,
  nextStep: number,
): string | undefined => {
  const origin = url.origin;
  const queryParams: URLSearchParams = url.searchParams;
  const userId = queryParams.get('user_id');
  const taskKey = queryParams.get('unique_task_key');
  const stepNumber = (queryParams.get('step_number') || 1) as number;

  if (taskName === AsaTaskName.SKILL) {
    const newUrl = `${origin}/pre-employment/skills/step${nextStep}?user_id=${userId}&unique_task_key=${taskKey}&step_number=${
      parseInt(stepNumber.toString()) + 1
    }`;
    return newUrl;
  }
  return undefined;
};

export const isIOS = () => {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
};

export const calculateFinalPrice = (price: number, discount: number) => {
  return price - (price * discount) / 100;
};

type EnumType = { [key: string]: string };

// Generic function to get enum key by value
export function getEnumKeyByValue<T extends EnumType>(
  enumObj: T,
  value: string,
): keyof T | undefined {
  // Get the keys of the enum object
  const keys = Object.keys(enumObj) as Array<keyof T>;

  // Find and return the key that matches the value
  return keys.find((key) => enumObj[key] === value);
}

export const calculatePasswordStrength = (password: string) => {
  let strength = 0;
  if (password.length >= 8) strength += 1; // Check length
  if (/[a-z]/.test(password)) strength += 1; // Check lowercase letters
  if (/[A-Z]/.test(password)) strength += 1; // Check uppercase letters
  if (/\d/.test(password)) strength += 1; // Check digits
  if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) strength += 1; // Check special characters
  return strength;
};

export function generateSubscriptionId(): string {
  // Generate a random 10-digit number
  const uniqueNumber = new Date().getTime();
  const id = `VOS-${uniqueNumber}`;
  return id;
}
