import dayjs from 'dayjs';

import {
  getOrganizationSubscriptions,
  upsertOrganziationSubscription,
} from '../../../services/database/organization-subscriptions';
import { EnvironmentVariable } from '../../../utils/constants';
import { SubscriptionData } from '../organization-dashboard/organization.types';

import {
  OrganizationSubscriptions,
  StatusType,
  SubscriptionTransactions,
  TransactionMetaData,
} from './organization-subscriptions-types';

export const dispatchUpsertNewSubscription = async (
  organizationId: string,
  metadata: TransactionMetaData,
  sessionId: string,
  amount: number,
  subscriptionId: string,
) => {
  // Create a new transaction from metadata
  const newTransaction: SubscriptionTransactions = {
    status: StatusType.INACTIVE,
    transactionId: sessionId,
    isPaymentProcessing: !!metadata.isPaymentProcessing || true,
    subscriptionType: metadata.subscriptionType,
    amount: amount,
  };

  // Update the subscription data with the new transaction
  const updatedTransactions = [newTransaction];
  const userCout = parseInt(metadata.userCount);
  const newSubscriptionData: OrganizationSubscriptions = {
    [subscriptionId]: {
      totalRegistrationCount: 0,
      userCount: userCout,
      transactions: updatedTransactions,
      startDate: metadata?.startDate,
      endDate: dayjs(metadata.startDate).utc().add(1, 'year').toISOString(),
      status: StatusType.INACTIVE,
    },
  };

  await upsertOrganziationSubscription(newSubscriptionData, organizationId);
};

export const dispatchGetSubscriptions = async (organizationId: string) => {
  const subscriptionData = await getOrganizationSubscriptions(organizationId);
  const formattedSubscriptions: SubscriptionData[] = Object.entries(subscriptionData).map(
    ([subscriptionId, subscription]) => {
      const latestTransaction = subscription.transactions?.[subscription.transactions.length - 1];
      return {
        subscriptionId,
        createdAt: subscription ? subscription.startDate : '',
        endDate: latestTransaction
          ? latestTransaction.status.includes(StatusType.ACTIVE)
            ? `${dayjs(subscription?.startDate).utc().add(1, 'year').utc()}`
            : '--'
          : '--',
        status: latestTransaction ? latestTransaction.status : 'Unknown',
        amount: latestTransaction ? latestTransaction?.amount / 100 : 0, // Add logic here to calculate or retrieve amount if available
        numberOfUsers: subscription.userCount || 0,
        users: [], // Update with actual user data if available
        isPaymentProccessing: latestTransaction ? latestTransaction.isPaymentProcessing : true,
        transactionId: latestTransaction ? latestTransaction?.transactionId : '',
      };
    },
  );

  return formattedSubscriptions;
};

// update transaction of organization
export const updateSubscriptionLastTransaction = async (
  organizationId: string,
  subscriptionId: string,
) => {
  try {
    const organizationSubscription = await getOrganizationSubscriptions(organizationId);

    if (organizationSubscription && organizationSubscription[subscriptionId]) {
      const subscriptionData = organizationSubscription[subscriptionId];
      const transactions = subscriptionData.transactions;
      subscriptionData.status = StatusType.ACTIVE;

      if (transactions && transactions.length > 0) {
        const lastTransactionIndex = transactions.length - 1;
        const lastTransaction = transactions[lastTransactionIndex];

        const updatedLastTransaction = {
          ...lastTransaction,
          isPaymentProcessing: false,
          status: StatusType.ACTIVE,
        };

        transactions[lastTransactionIndex] = updatedLastTransaction;

        await upsertOrganziationSubscription(
          { [subscriptionId]: { ...subscriptionData, transactions } },
          organizationId,
        );

        return { message: 'Subscription updated successfully' };
      } else {
        return { message: 'No transactions found for this subscription' };
      }
    } else {
      return { message: 'Subscription not found' };
    }
  } catch (error) {
    throw new Error(`Error updating subscription: ${error}`);
  }
};

export const dispatchGetUsers = async (organizationId: string, subscriptionId: string) => {
  const usersDataUrl = process.env[EnvironmentVariable.REACT_APP_ORGANIZATION_FETCH_USER_URL];
  if (usersDataUrl) {
    const response = await fetch(usersDataUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ organizationId, subscriptionId }),
    });
    const result = await response.json();
    return result.users;
  }
};
