import CloseIcon from '@mui/icons-material/Close';
import {
  Modal,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TablePagination,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { SubscriptionUsersData } from '../../redux/slice/organization-dashboard/organization.types';

type UserDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  users: SubscriptionUsersData[];
  onRenew?: (user: SubscriptionUsersData) => void;
  startDate?: string;
  endDate?: string;
};

const UserDetailsModal: React.FC<UserDetailsModalProps> = ({ isOpen, onClose, users }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isOpen]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedUsers = users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="user-details-modal-title"
      aria-describedby="user-details-modal-description"
      className="flex items-center justify-center p-4" // Add padding for mobile view
      disableEscapeKeyDown
    >
      <div className="relative bg-white w-full max-w-3xl p-6 rounded-lg shadow-lg border border-gray-300 overflow-auto max-h-[80vh]">
        {/* Modal Header */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-purple-700 hover:bg-purple-300"
        >
          <CloseIcon />
        </IconButton>
        <h2 className="text-purple-600 text-[1.5rem] text-center mb-5">User Details</h2>

        <div className="overflow-x-auto">
          <Table className="min-w-full rounded-md">
            <TableHead>
              <TableRow>
                <TableCell className="font-bold text-gray-600 module-header">Name</TableCell>
                <TableCell className="font-bold text-gray-600 module-header">Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedUsers.length > 0 ? (
                paginatedUsers.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell className="py-2 text-gray-700 module-header">{user.name}</TableCell>
                    <TableCell className="py-2 text-gray-700 data-cell ">{user.email}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography align="center" variant="body1">
                      No User Registered
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {/* Pagination */}
        <TablePagination
          component="div"
          count={users.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 15]}
          className="mt-4"
        />
      </div>
    </Modal>
  );
};

export default UserDetailsModal;
