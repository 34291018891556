import _ from 'lodash';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import emotional_chapter4_img1 from '../../../../assets/images/courses/emotional-intelligence/chapter4/emotional_chapter4_img1.jpg';
import m1 from '../../../../assets/images/courses/emotional-intelligence/chapter4/m1.jpg';
import m2 from '../../../../assets/images/courses/emotional-intelligence/chapter4/m2.jpg';
import m3 from '../../../../assets/images/courses/emotional-intelligence/chapter4/m3.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  course_sorting_matching_questions,
  course_sorting_questions,
} from '../../../../courses/emotional-intelligence/emotional-intelligence-course-question';
import {
  CourseMatchingQuestion,
  CourseSortingQuestion,
  ShortingMatchingBase,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import { dispatchUpdateUserCourseQuestionResponse } from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CustomAccordion } from '../../../common/Accordion';
import FlipCard from '../../FlipCard';
import { InfoBlock } from '../../InfoBlock';
import { MatchingQuestionBlock } from '../../MatchingQuestion/CourseMatchingQuestion';
import { MediaBlock } from '../../MediaBlock';
import { SimpleCarouselComponent } from '../../SimpleCarouselComponent';
import { CourseSortingQuestionBlock } from '../../SortingQuestion/CourseSortingQuestion';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type SortingAnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};

export const EmotionalIntelligence4: React.FC<ChapterProps> = ({
  chapter,
  totalPages,
  onNextChapter,
}) => {
  const [isPageDirty, setIsPageDirty] = useState(true);
  const dispatch = useAppDispatch();
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const courseSortingQuestion = course_sorting_questions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();

  const courseMatchingQuestion = course_sorting_matching_questions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const handleSortingExcerciseCompleted = async (sortingAnswers: SortingAnswersState) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[courseSortingQuestion[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[courseMatchingQuestion[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [courseSortingQuestion[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(Object.keys(sortingAnswers)[0]),
                ),
                sortingAnswers,
              ],
              matchingQuestions: [...userMatchingAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleSortingRetake = async (questionId: string) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[courseSortingQuestion[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];
    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[courseMatchingQuestion[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [courseSortingQuestion[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(questionId),
                ),
              ],
              matchingQuestions: [...userMatchingAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleMatchingRetake = async (questionId: string) => {
    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[courseMatchingQuestion[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions || [];

    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[courseSortingQuestion[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [courseMatchingQuestion[0].course_id]: {
          chapters: {
            [chapter.id]: {
              matchingQuestions: [
                ...userMatchingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(questionId),
                ),
              ],
              sortingQuestions: [...userSortingAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleMatchingExcerciseCompleted = async (matchingAnswers: SortingAnswersState) => {
    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[courseMatchingQuestion[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions || [];
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[courseSortingQuestion[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [courseMatchingQuestion[0].course_id]: {
          chapters: {
            [chapter.id]: {
              matchingQuestions: [
                ...userMatchingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(Object.keys(matchingAnswers)[0]),
                ),
                matchingAnswers,
              ],
              sortingQuestions: [...userSortingAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  useEffect(() => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[courseSortingQuestion[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions;
    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[courseMatchingQuestion[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions;
    if (
      Object.keys(userCourseQuestionResponseContext).length &&
      userSortingAnswerResponse?.length &&
      userMatchingAnswerResponse?.length
    ) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext, isPageDirty]);
  const accordionData = [
    {
      id: 'panel1',
      title: 'Define Your Whys',
      content:
        '<div class="fr-view"><p>Motivation requires a purpose. If you can’t remember <em>why</em> you’re making sacrifices, then it’s difficult to muster the energy to keep going. Take a moment to stop and define your <em>whys</em> if you find yourself feeling stuck and asking questions such as, <em>“What’s the point? Why does this even matter? Should I just give up?”</em></p><p><br></p><p>A <em>why</em> is your reason for doing something. To find that reason, try following the <strong>six-cylinder model</strong>. The six-cylinder model helps you look at different areas of your life and see what inspires you in terms of your <strong>career/work</strong>, <strong>family</strong>, <strong>finance</strong>, <strong>health</strong>, <strong>social</strong>, and <strong>spiritual</strong> goals. For example, when the morning alarm goes off, ask yourself: <em>“Why do I want to get up right now, and why is that more important than sleeping in?”</em></p><p><br></p><p>Maybe your reason is to move up the career ladder. Maybe it’s so that you can financially provide for your family. Or, perhaps, it’s because you know your job helps others and you want to make an impact. Everyone has different motivators. So, think about what inspires you. Then, write those motivators down and set goals that align with your <em>whys</em>.</p></div>',
    },
    {
      id: 'panel2',
      title: 'Break Down SMART Goals ',
      content:
        '<div class="fr-view"><p>Motivation requires direction and long-term perspective. To maintain momentum, you need clear targets and goals to strive toward. The problem with goals, however, is that we often forget to break them down. We focus on the big picture, and—after some time—we start to feel like the payoff will never happen.&nbsp;</p><p><br></p><p>To maintain motivation, come up with an objective, and then break it down into smaller, attainable pieces. If you want to run a marathon, for example, set your sights on running a 5K, a 10K, and a half marathon first. Or, if you want to write a novel, start with writing 500 words a day—not all 100,000 words at once. Give seemingly impossible goals a footing in reality, and you’ll be more likely to keep going.</p><p><br></p><p>When creating those breakdowns, also remember to keep them <strong>SMART</strong> (which stands for specific, measurable, attainable, realistic, and time-based). Be <strong>specific</strong> about what you want to accomplish, make your milestones <strong>measurable</strong> so that you can track them, and set yourself up for success by creating <strong>attainable</strong> objectives that are <strong>realistic</strong> and<strong>&nbsp;time-based</strong>. &nbsp;</p></div>',
    },
    {
      id: 'panel3',
      title: 'Celebrate Wins',
      content:
        '<div class="fr-view"><p>To keep your spirits up, don’t just focus on the reward at the finish line. Instead, celebrate your accomplishments and wins along the way.</p><p><br></p><p>The next time you meet a milestone, recognize that success, feel proud, and allow yourself to indulge in a small reward. Maybe that’s a night out with friends, a purchase you’ve been eying, a massage you’ve wanted to book, or a movie you’ve been hoping to see.&nbsp;</p><p><br></p><p>Rather than using incentives as procrastination tools, save them for after a task is done. For example, if your favorite TV show just released a new episode, resist the temptation to watch it immediately. Instead, wait until after you’ve finished your work. That way—rather than slowing you down—the reward will keep you going.</p></div>',
    },
    {
      id: 'panel4',
      title: 'Don’t Compare Yourself to Others',
      content:
        '<div class="fr-view"><p>One of the quickest ways to squash motivation is to compare yourself to others. Such comparisons can lead to feelings of defeat, questions of whether you’re good enough, and overall unhappiness. They waste your time, hold you back, and often paint an unrealistic picture of reality.</p><p><br></p><p>You never know what’s going on behind the curtain of someone else’s life. So, rather than telling yourself, <em>“She’s so much smarter than me,”</em> or<em>&nbsp;“I’ll never be as good as him,”</em> focus on yourself. Look within, not out. Track your personal progress. Look at where you’ve been and how you’ve improved. Only compare yourself to yourself.</p></div>',
    },
    {
      id: 'panel5',
      title: 'Control Self-Talk',
      content:
        '<div class="fr-view"><p>We all have an inner voice that’s constantly feeding us information—some positive, some negative. To maintain motivation, it’s important to control that self-talk so that it acts as a coach rather than a critic.&nbsp;</p><p><br></p><p>If you don’t believe in yourself, it’s difficult to push ahead or remain optimistic in the face of a setback. So, rather than telling yourself,<em>&nbsp;“It’s not going to happen,”</em> control that negativity by telling yourself<em>, “My goals are possible.”&nbsp;</em>Act as your own coach, and you’re more likely to put your energy into making your goals a reality.&nbsp;</p><p><br></p><p>Remember, you have the power to choose which voice you feed energy into. Acknowledge when you’re pessimistic, control that self-talk, and tell yourself:<em>&nbsp;“Stop, that’s enough. I can do this. I will keep trying.”</em></p></div>',
    },
  ];
  const slidesData = [
    {
      heading: 'Achievement Drive',
      content: `<div class="fr-view"><p>The first benefit of self-motivation is its association with an <strong>achievement drive</strong>: a drive to improve and push forward. Those with an achievement drive are committed to:</p><ul><li>Creating challenging goals</li><li>Working toward their objectives</li><li>Maintaining a results-oriented mindset</li></ul><p>With a strong achievement drive, you’ll set a standard of excellence for others to follow, avoid complacency, and inspire others with future-focused visions.</p></div>`,
    },
    {
      heading: 'Commitment',
      content: `<div class="fr-view"><p>Next, self-motivation and <strong>commitment</strong> go hand-in-hand. Intrinsic motivation inspires follow-through, resilience, and a willingness to make sacrifices for your goals. For example, you may avoid entertaining distractions like gossiping with coworkers because you’re committed to getting your work done.</p><p>A commitment drive helps you make headway on your goals and earn the confidence of your colleagues.</p></div>`,
    },
    {
      heading: 'Initiative',
      content: `<div class="fr-view"><p>Third, self-motivation boosts your <strong>initiative</strong> and readiness to take action. It’s characterized by a willingness to take risks, seek growth, seize opportunities, and act rather than react.&nbsp;</p><p>Those with a strong initiative succeed in the workplace because they’re proactive and unafraid to step forward when a need arises.</p></div>`,
    },
    {
      heading: 'Optimism',
      content: `<div class="fr-view"><p>Finally, intrinsic motivation prompts optimism. Rather than giving up when faced with setbacks, you’ll persevere and maintain a positive, hopeful, and confident outlook.</p><p>Those with this quality work through obstacles and see challenges as growth-building opportunities. An inspiring quality that benefits both you and your team, optimism can motivate a team to push past temporary hiccups and strive toward success.</p></div>`,
    },
  ];
  return (
    <>
      <div className="content-wrap border-l-0 lg:border-l pl-0 lg:pl-8 pt-8 pb-10">
        <h2 className="font-semibold text-3xl">Increasing Self-Motivation</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <MediaBlock mediaImage={emotional_chapter4_img1} />

        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-5">Are You Up?</h3>
        <p>
          It’s Monday morning, your alarm clock is blaring, and the last thing you want to do is
          move. So, what do you do? Do you press snooze? Or, do you tell yourself, “I’ve got this,”
          and muster the energy to get out of bed?
        </p>
        <p className="text-3xl font-light text-center my-8">
          It’s you against yourself. Those with high self-motivation choose the option that’s in
          their best long-term interest.
        </p>
        <p>
          As the third component of emotional intelligence, self-motivation takes discipline,
          stamina, and grit to push forward—especially when a more comfortable option exists. In
          this lesson, you’ll learn what sets self-motivation apart, why it’s a necessary competency
          for emotional intelligence, and how to develop yours.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls controlsList="nodownload">
            <source
              src={chapter?.course_video_url && chapter?.course_video_url[0]}
              type="video/mp4"
            />
          </video>
        </div>
        <p>
          Watch the video to learn what motivation is, what sets self-motivation apart from other
          motivators, and how to improve your self-motivation.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">Defining Motivation</h3>
        <p>
          Motivation inspires us to act. It helps us accomplish what needs to get done, stay
          energized, and move forward even when an easier or more attractive option exists.
        </p>
        <br />
        <p>
          Rather than succumbing to instant gratification activities like sleeping in,
          binge-watching TV, mindlessly snacking, or procrastinating, motivation prompts us to push
          through challenges—giving us the resolve to move forward, even if we’d rather stay still.
        </p>
        <MediaBlock mediaImage={m1}>
          <p className="text-3xl leading-10 font-light w-3/4 text-center mx-auto">
            "Ability is what you’re capable of doing. Motivation determines what you do."
            <br />
            <span className="text-base font-bold">Lou Holtz</span>
          </p>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">3 Types of Motivators</h3>
        <p>
          We can cultivate motivation from many sources, some external and some internal. Flip the
          cards below to learn about the three primary motivators: <strong>obligatory</strong>,{' '}
          <strong>extrinsic</strong>, and <strong>intrinsic.</strong>
        </p>
        <div className="my-10">
          <div className="flex justify-center gap-5 text-2xl font-light">
            <FlipCard>
              <div className="front-content">
                <p>Obligatory</p>
              </div>
              <div className="back-content text-center  w-full h-full flex items-center text-lg">
                <p>
                  Comes from a sense of <strong>duty</strong> and <strong>ethics</strong>. For
                  example, you might feel <em>obligated</em> to attend a family function, even if
                  you’d rather spend the day at home.&nbsp;
                </p>
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content">
                <p>Extrinsic</p>
              </div>
              <div className="back-content text-center  w-full h-full flex items-center text-lg">
                <p>
                  Comes from <strong>outside</strong>. You’re motivated to action because you expect
                  an external reward such as money, power, status, or recognition.
                </p>
              </div>
            </FlipCard>
            <FlipCard>
              <div className="front-content">
                <p>Intrinsic</p>
              </div>
              <div className="back-content text-center  w-full h-full flex items-center text-lg">
                <p>
                  Comes from <strong>within</strong>. It’s a <em>personal,</em> self-motivational
                  drive to push ahead and strive toward individual fulfillment. You work for a
                  result, not a reward.
                </p>
              </div>
            </FlipCard>
          </div>
        </div>
        <p className="text-3xl font-light text-center my-8">
          Of all the motivators, intrinsic—or self—motivation is the most stable because only{' '}
          <em>
            <strong>you</strong>
          </em>{' '}
          can control it. No one else can give it, and no one can take it away.
        </p>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">What’s the Motivator?</h3>
        <p>
          Based on your understanding, can you drag each of the cards below into the correct
          motivational category?
        </p>
        {/* drag-drop card */}
        <DndProvider backend={HTML5Backend}>
          <CourseSortingQuestionBlock
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetake={handleSortingRetake}
            question={courseSortingQuestion[0] as CourseSortingQuestion}
            submitedCorrectAnswerCount={
              userCourseQuestionResponseContext[courseSortingQuestion[0].course_id]?.chapters[
                chapter.id
              ]?.sortingQuestions
                ?.filter((x) => Object.keys(x).filter((key) => key === courseSortingQuestion[0].id))
                .map(
                  (x) =>
                    x[courseSortingQuestion[0].id].filter(
                      (x) => x.answer?.id === x.correct_answer?.id,
                    ).length,
                )[0] ?? null
            }
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            submitSortingQuestion={handleSortingExcerciseCompleted}
          />
        </DndProvider>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">
          Benefits of Self-Motivation
        </h3>
        <p>
          Self-motivation is an intrinsic motivator that comes from within. You don’t need an
          external reward or recognition, and you don’t need to feel morally obligated to act.
        </p>
        <br />
        <p>
          With high self-motivation, your internal drive pushes you to pursue your goals, stay
          energized, and remain resilient. You’ll use your emotions strategically to move
          forward—accomplishing tasks that may otherwise seem out of reach.
        </p>
        <br />
        <p>
          Advance through the interaction to learn four benefits—and defining qualities—that
          accompany strong self-motivation.
        </p>
        <div className="border rounded-3xl pt-10 mt-7">
          <SimpleCarouselComponent data={slidesData} />
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">
          Improving Your Self-Motivation
        </h3>
        <p>
          So, how can you reap the benefits of self-motivation? Expand the rows below to learn five
          techniques to develop your skills.
        </p>

        <br />
        {accordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}
        <br />
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">Six Cylinder Activity</h3>
        <p>
          See an overview of the activity instructions below. Access the activity by clicking on the
          Play button (you will be guided through the activity).
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">Activity Instructions:</h3>
        <div>
          <p>
            <strong>Step 1:&nbsp;</strong>For each of the six cylinder areas, ask yourself "How well
            am I performing?" in that area. For example, if you're looking at 'Health,' ask the
            question, "How satisfied am I with my health?"&nbsp;
          </p>
          <br />
          <p>
            Slide the bar on each cylinder to the score you give it out of 10, where 1 is poor and
            10 is great.&nbsp;
          </p>
          <br />
          <p>
            <strong>Step 2:</strong> The result is a picture of your life balance. Answer the
            questions and be sure to print or save your responses for future reference.
          </p>
          <br />
          <p>
            <strong>Step 3:</strong> Identify your ideal score on each cylinder by adjusting the bar
            on the cylinders and asking yourself what you need to do to get your life into balance.
            Enter what you need to do in the provided text entry areas.&nbsp;
          </p>
          <br />
          <p>
            <strong>Step 4:</strong> Develop action plans to improve the score in each cylinder you
            want to improve. Think about what you have learned and are willing to commit to do to
            demonstrate balance in your life. Capture what you are willing to Start, Stop, and/or
            Continue doing.&nbsp;
          </p>
        </div>
        <br />
        <br />
        <p>
          <label className="custom-checkbox">
            What I will stop doing that may hinder my ability to maintain life balance
            <input type="checkbox" />
            <span className="checkmark"></span>
          </label>
        </p>
        <br />
        <p>
          <label className="custom-checkbox">
            What I will start doing to more effectively build balance in my life
            <input type="checkbox" />
            <span className="checkmark"></span>
          </label>
        </p>
        <br />
        <p>
          <label className="custom-checkbox">
            What I will continue to do to maintain balance in my life
            <input type="checkbox" />
            <span className="checkmark"></span>
          </label>
        </p>
        <br />
        <br />
        <InfoBlock
          title="Note: "
          text=" Don’t forget to share this plan with a family member or a trusted person to hold yourself accountable."
        />
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">
          Interactive storyline activity will be added here.
        </h3>
        {/* video card with  question */}
        <p className="text-3xl my-8 leading-10 font-light text-center">
          Remember, when you encounter set backs or difficulty your cylinders can get out of balance
          and your personal best is out of sync. This tool helps you keep the balance on the inside.
          Keep your stressors from work at work; keep your stressors from your personal life
          personal. Your ability and skills to isolate thoughts into these six simple cylinders will
          enable you peace and personal performance as you grow
        </p>
        <MediaBlock mediaImage={m2}>
          <p className="text-3xl leading-10 font-light w-3/4">
            Whether you think you can, or you think you can’t—you’re right.
            <br />
            <span className="text-base font-bold">Henry Ford</span>
          </p>
        </MediaBlock>
        {/* drag-drop card */}
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">
          How Can You Build Self-Motivation?
        </h3>
        <p className=" mb-6">
          Check your understanding by completing the following matching activity.
        </p>
        <div className="border rounded-3xl p-8 text-lg mb-6">
          <div className="border-b-2  mb-6">
            <p className=" mb-6">
              You’re struggling to stay motivated and are experiencing a lot of negative self-talk.
              Can you match the negative statements on the left with the correct self-motivating
              solution on the right?
            </p>
          </div>
          <DndProvider backend={HTML5Backend}>
            <MatchingQuestionBlock
              questionData={courseMatchingQuestion[0] as CourseMatchingQuestion}
              submitedAnswer={
                _.flatMap(
                  userCourseQuestionResponseContext[courseMatchingQuestion[0].course_id]?.chapters[
                    chapter.id
                  ]?.matchingQuestions?.filter((x) =>
                    Object.keys(x).filter((key) => key === courseMatchingQuestion[0].id),
                  ),
                  _.values,
                )[0]
              }
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onRetake={handleMatchingRetake}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              submitMatchingQuestion={handleMatchingExcerciseCompleted}
            />
          </DndProvider>
        </div>
        <MediaBlock mediaImage={m3}>
          <p className="text-2xl leading-10 font-light">
            <span className="font-bold">Motivation comes and goes.</span> Don’t be dissuaded if you
            feel like your motivation is flatlining. Some days, you’ll feel better than others.
            What’s important is not to feel defeated. If you’re feeling down on yourself, practice
            the tips covered in this lesson, know that everyone goes through ups and downs, and set
            your sights on getting back on your feet. Whether you think you can, or you think you
            can’t—you’re right.
          </p>
        </MediaBlock>

        <p className="text-3xl my-8 leading-10 font-light text-center">
          As you develop your self-awareness, regulation, and motivation, consider how you can also
          look outside yourself and understand how others are feeling. In the next lesson, you’ll
          learn more details about how to develop your social competence through empathy.
        </p>
        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full uppercase"
        >
          Developing Empathy
        </button>
      </div>
    </>
  );
};
