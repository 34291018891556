import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import EmmaHill from '../../../../assets/images/courses/communication-basics/chapter13/EmmaHill.jpg';
import {
  course_questions as courseQuestions,
  course_sorting_questions as sortingQuestions,
} from '../../../../courses/communication-basics/communication-basics.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseQuestionAnswer,
  QuestionOption,
  ShortingMatchingBase,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  dispatchUpdateUserCourseQuestionResponse,
  UserChapterQuestion,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CarouselComponent } from '../../CarouselComponent';
import { CourseQuestionCard } from '../../CourseQuestionCard';
import { MediaBlock } from '../../MediaBlock';
import { CourseSortingQuestionBlock } from '../../SortingQuestion/CourseSortingQuestion';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};
type SortingAnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};
export const CommunicationBasicsChapter13: React.FC<ChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const slidesData = [
    {
      heading: 'Edit First, Proofread Second',
      paragraph: `<p>Because editing and proofreading use different mental processes, you should avoid focusing on both revisions at once. Instead, <span class="font-bold">edit</span> first and <span class="font-bold">proofread</span> second.</p><p><br/></p>
        <p>Click through this process interaction to learn more.</p>`,
      list: [],
    },
    {
      heading: 'Editing',
      paragraph: `<p>When your draft is complete, it’s time to start the editing process. This is your chance to carefully reread your sentences, focusing on the big picture.</p><p><br/></p>
      <p>The editing process is about content. At this stage, your primary goal is to look at the tone and aesthetic of your document. Consider your reader’s perspective and make sure your information is well-organized, your paragraphs flow logically, and your arguments are backed up by supporting evidence.</p><p><br/></p>
      <p>During the editing process, ask yourself: <span class="italic">Do my sentences move evenly from one to the next? Does everything make sense? Do I stay on topic and give enough background information?</span></p>`,
      list: [],
    },
    {
      heading: 'Proofreading',
      paragraph: `<p>Once you’ve finished editing your content, it’s time to look at the smaller details. When proofreading, you should no longer focus on making any significant changes. Instead, this step is about analyzing your sentences word for word. If you find yourself making bigger changes during this process, it’s best to go back to the editing stage.</p><p><br/></p>
      <p>When proofreading your work, ask yourself: <span class="italic">Are all words spelled correctly? Are my sentences structured appropriately? Did I use proper punctuation?</span></p>`,
      list: [],
    },
    {
      heading: 'Summary',
      paragraph: `<p>For the readability and clarity of your piece, always remember to <span class="font-bold">edit</span> your content first and then scale down to <span class="font-bold">proofread</span> for grammar, spelling, and punctuation mistakes.</p>`,
      list: [],
    },
  ];
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);

  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const [, setAnswers] = useState<AnswersState>({});
  const chapterQuestions = courseQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const chapterSortingQuestions = sortingQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              sortingQuestions: [...userSortingAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];
    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              sortingQuestions: [...userSortingAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };

  const handleSortingExcerciseCompleted = async (sortingAnswers: SortingAnswersState) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];
    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterSortingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(Object.keys(sortingAnswers)[0]),
                ),
                sortingAnswers,
              ],
              questions: [...userAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleSortingRetake = async (questionId: string) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];
    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterSortingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(questionId),
                ),
              ],
              questions: [...userAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  useEffect(() => {
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions;
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions;
    if (
      Object.keys(userCourseQuestionResponseContext).length &&
      userAnswerResponse?.length &&
      userSortingAnswerResponse?.length
    ) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);

  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">How to Edit and Proofread Your Work</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>

        <p className="text-2xl text-center leading-10 mt-16 font-light mb-16">
          When looking over our own writing, we tend to fill in the blanks. We know what we’re
          trying to say, and so we read our documents as though we’ve already said it. We miss the
          errors that are right in front of us. Thankfully, by learning how to edit and proofread
          your work carefully, you can better spot these mistakes before pressing the “send” button.
        </p>
        <p className="mb-8 text-xl font-light">
          In this lesson, we’ll take an in-depth look at why you need to first edit and then
          proofread your writing. We’ll then give you five tried and tested tips for how you can do
          this most effectively, thus improving the overall quality and readability of your work.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls controlsList="nodownload">
            <source
              src={chapter?.course_video_url && chapter?.course_video_url[0]}
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          Why You Need to Edit and Proofread Your Work
        </h3>
        <p className="mb-8">
          If you don’t edit or proofread your writing, careless errors may quickly overshadow your
          work.
        </p>
        <ul className="list-decimal pl-7 mt-8 mb-10 text-xl">
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Editing</span> involves looking at your content. Do your
            sentences flow smoothly? Are your details clear? Is your meaning on target? By taking a
            closer look at how you share your ideas and information, you won’t leave your reader
            questioning your message or hesitating over confusing language and expressions.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Proofreading</span> is about polishing your work and looking
            for grammar, spelling, and punctuation mistakes. These are errors that a spell-checker
            often misses, and they can leave your readers stumbling over ill-constructed sentences,
            missing punctuation marks, and the wrong use of homonyms such as “their,” “they’re,” and
            “there.”
          </li>
        </ul>
        <p className="text-xl italic mb-10">
          Plainly put, by editing and proofreading your work, you can help your audience focus on
          your message, rather than on your mistakes.
        </p>
        <div className="border rounded-3xl mr-8">
          <CarouselComponent data={slidesData} />
        </div>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          The Difference Between Editing and Proofreading
        </h3>
        <p className="mb-8">
          Editing and proofreading are two different processes. Based on what you’ve learned, can
          you match the statements below with their corresponding cards?
        </p>
        <DndProvider backend={HTML5Backend}>
          <CourseSortingQuestionBlock // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetake={handleSortingRetake}
            question={chapterSortingQuestions[0]}
            submitedCorrectAnswerCount={
              userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[
                chapter.id
              ]?.sortingQuestions
                ?.filter((x) =>
                  Object.keys(x).filter((key) => key === chapterSortingQuestions[0].id),
                )
                .map(
                  (x) =>
                    x[chapterSortingQuestions[0].id].filter(
                      (x) => x.answer?.id === x.correct_answer?.id,
                    ).length,
                )[0] ?? null
            }
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            submitSortingQuestion={handleSortingExcerciseCompleted}
          />
        </DndProvider>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          Five Tips for Reviewing Your Work
        </h3>
        <p className="mb-8">
          To make sure you’re not glancing over common errors, follow these five tips—just remember
          to edit first and proofread second.
        </p>
        <ul className="list-decimal pl-7 mt-8 mb-10 text-xl">
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Focus on one error at a time. </span> It’s tempting to look
            at your work as a whole, but if you’re evaluating content, grammar, spelling, and
            punctuation all at once, you’re likely to miss some key details. Pick one focus at a
            time—whether it be overall clarity, missing examples, punctuation, spelling, or grammar.
            By following this process, you’ll be better able to zero in on those problems that you
            may have otherwise ignored.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Read your work out loud (and slowly). </span> Listen to how
            your words and sentences sound. Does the information flow smoothly, or are you
            stuttering over the text? How about your vocabulary? Do your words sound natural, or
            does it seem like you’ve pulled the terms out of a thesaurus? These errors are all
            easier to find when you stop reading and start listening.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Step away.</span> You could read something numerous times
            and still miss an error such as a repeat repeat word (see what we did there?) or a
            missing article. When a piece of writing is too familiar, we tend to skip over our
            mistakes. If you have the time, step away, take a walk, or go to lunch. Do anything you
            can to clear your head. That way, you can return to your work with fresh eyes.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Change the format.</span> Have you ever reread an email
            after pressing “send” and noticed several errors you didn’t spot before? That’s likely
            because you changed the format of your writing, therefore tricking your mind into
            thinking this was a new document. Here’s a tip: Try printing out your work material,
            placing it in a different medium, or changing the font size, color, or style.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Read it backward.</span> By reading your piece from back to
            front, you’ll force yourself to slow down and focus. For spelling errors, start with the
            last word and work your way up. For grammatical errors, try reading your work sentence
            by sentence from the bottom up; that will help you to narrow in on your structure
            instead of your content.
          </li>
        </ul>
        <MediaBlock mediaImage={EmmaHill}>
          <p className="text-3xl text-center leading-10 font-light lg:w-3/4 mx-auto">
            The first draft is black and white. Editing gives the story color.
            <br />
            <span className="text-base">Emma Hill</span>
          </p>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">What Would You Do?</h3>
        <CourseQuestionCard
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmitAnswer={handleCourseAnswerSubmit}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetakeQuestion={handleQuestionRetake}
          question={chapterQuestions[0]}
        />

        <p className="text-2xl text-center leading-10 font-light mb-10 mt-10">
          If you apply the tips and tricks you've learned in the written communication chapter of
          this course, the quality of your writing will be greatly improved. Before you close this
          course, take a few minutes to review some of the key points in the next lesson.
        </p>

        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full uppercase"
        >
          Summary
        </button>
      </div>
    </>
  );
};
