import { doc, DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore';

import { OrganizationSubscriptions } from '../../redux/slice/organization-subscriptions/organization-subscriptions-types';
import logger from '../logger';

import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';
// import { getIdForFormRecord } from './database.utils';

const database: DatabaseTable = DatabaseTable.ORGANIZATION_SUBSCRIPTIONS;

// here recordId is oganizationId
async function updateRecord<T extends Record<string, unknown>>(
  dto: T,
  recordId: string,
): Promise<void> {
  //   const recordId: string = await getIdForFormRecord();
  await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
    merge: true,
  });
}

// here recordId is oganizationId
export async function getOrganizationSubscriptions(recordId: string) {
  try {
    // const recordId: string = await getIdForFormRecord();
    const result: DocumentSnapshot<OrganizationSubscriptions> = await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    );
    return result.data() ?? {};
  } catch (error) {
    logger.error('Failed to retrieve subscription data.', error);
    throw error;
  }
}

// here recordId is oganizationId
export async function upsertOrganziationSubscription(
  dto: OrganizationSubscriptions,
  recordId: string,
): Promise<void> {
  try {
    await updateRecord(dto, recordId);
    logger.debug('Saved user course question.');
  } catch (e) {
    logger.error('Failed to update organization subscriptions.', e);
    throw e;
  }
}
