import { useEffect, useState } from 'react';

import georgeOrwall from '../../../../assets/images/courses/communication-basics/chapter7/georgeOrwall.jpeg';
import { course_questions as courseQuestions } from '../../../../courses/communication-basics/communication-basics.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseQuestionAnswer,
  QuestionOption,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  dispatchUpdateUserCourseQuestionResponse,
  UserChapterQuestion,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CustomAccordion } from '../../../common/Accordion';
import { CarouselComponent } from '../../CarouselComponent';
import { CourseQuestionCard } from '../../CourseQuestionCard';
import { MediaBlock } from '../../MediaBlock';
interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};
export const CommunicationBasicsChapter7: React.FC<ChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);

  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const [, setAnswers] = useState<AnswersState>({});
  const chapterQuestions = courseQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );

  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  useEffect(() => {
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions;
    if (Object.keys(userCourseQuestionResponseContext).length && userAnswerResponse?.length) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);
  const slidesData = [
    {
      heading: 'Organizing Your Writing',
      paragraph: `<p>Even if your sentences are clear and your words flow smoothly from one to the next, poor organization can doom your writing. </p><p><br/></p>
      <p>Click through this step-by-step process to learn how to keep your writing organized.</p>`,
      list: [],
    },
    {
      heading: 'Define Your Purpose',
      paragraph: `<p>The first question you should ask yourself before starting to write a piece is, <span class="italic">“Why am I writing this?”</span> Effective writing has a defined purpose. You need to know yours.</p>
      <p><br/></p><p>Do you want to <span class="font-bold">inform</span> your reader, <span class="font-bold">persuade</span> them, or <span class="font-bold">convey goodwill?</span> If you understand why you’re writing, you’ll then have a better idea of what information to include and how to organize that information. In short, knowing your goal can guide how you approach your work.</p>
      <p><br/></p><p>When defining your purpose, consider these questions:</p><p><br/></p>
      <ul><li class="pb-3">Why am I writing this document?</li><li class="pb-3">What do I want to communicate?</li><li class="pb-3">What do I need my audience to know or do?</li></ul>`,
      list: [],
    },
    {
      heading: 'Choose Your Medium',
      paragraph: `<p>Once you know your purpose, your next step is to choose the right medium. Common workplace mediums include emails, handwritten notes, instant messages, letters, and reports. The medium you choose will set the stage for your communication: it can immediately express how formal, urgent, or complex your message is.</p><p><br/></p>
      <p>If you choose the wrong medium, your audience may misunderstand your message. Or, your message may never make it into your reader’s hands. Maintain the success of your communication by picking the right medium for the job.</p><p><br/></p>
      <p>When considering which medium works best for your message, ask yourself:</p><p><br/></p>
     <ul><li class="pb-3">What medium best expresses my purpose?</li><li class="pb-3">What medium best fits the type of information I’m sharing?</li><li class="pb-3">Do I need to send an informal instant message or send a well-thought-out email? Do I need to write a detailed report or will a formal letter do?</li></ul>`,
      list: [],
    },
    {
      heading: 'Consider Your Audience',
      paragraph: `<p>Now that you’ve chosen a medium, it’s time to consider your audience. You’ll want to tailor your tone and the level of detail you include based on their knowledge of and perspective on the topic, as well as their relationship with you. </p><p><br/></p>
      <p>For instance, consider how differently you’d communicate with an executive who has little background on a project versus a close teammate who has worked alongside you on it. </p><p><br/></p>
      <p>To help you determine what tone and level of detail you should provide, answer the following questions about your audience:</p><p><br/></p>
     <ul><li class="pb-3">What’s my reader’s opinion on this subject?</li><li class="pb-3">How much information does my reader need to know?</li><li class="pb-3">What’s my reader’s level of understanding of the topic?</li></ul>`,
      list: [],
    },
    {
      heading: 'Outline Your Work',
      paragraph: `<p>You know what to say. You know how you’re going to say it. You know your audience. Now, it’s time to structure your work.</p><p><br/></p>
      <p>Start by outlining an introduction that summarizes the purpose of your piece. Then, consider the most logical order for your supporting points, keeping in mind that every paragraph should have a single focus. Finally, think about how you can wrap up your content with a clear conclusion.</p><p><br/></p>
      <p>When outlining your work, ask yourself:</p><p><br/></p>
     <ul><li class="pb-3">Does my introduction explain the purpose of the piece?</li><li class="pb-3">Do my body paragraphs contain details that help support this purpose?</li><li class="pb-3">Does my conclusion wrap up things clearly and prompt the action I want?</li></ul>`,
      list: [],
    },
    {
      heading: 'Summary',
      paragraph: `<p>To keep your writing organized and easy to follow, always remember to follow a four-step process: <span class="font-bold">determine your purpose, choose your medium, consider your audience,</span> and finally, <span class="font-bold">outline your work.</span></p>`,
      list: [],
    },
  ];
  const accordionData = [
    {
      id: 'panel1',
      title: 'Use the active voice.',
      content:
        'When using the active voice, place the subject of your sentence (the person who’s doing something) before the verb. The structure follows this order: subject-verb-object. It helps keep your writing concise. For example, you’d say: “Greg read the book.” An active voice quickly gets the point across and shows confidence in the statement.',
    },
    {
      id: 'panel2',
      title: '​Keep language strong and simple.',
      content:
        'Your aim as a writer is to create impactful content that’s reader-friendly. Effective writing doesn’t use flowery language and million-dollar words; it uses clear, straightforward vocabulary that your readers can relate to. Instead of writing “Let’s convene,” for example, write “Let’s meet.” ',
    },
    {
      id: 'panel3',
      title: '​Eliminate needless words and phrases.',
      content:
        'We tend to write the same way we speak, but this approach can create long-winded, confusing sentences. Good writing is precise. Don’t write to fill up the page. Rather, write with a purpose. Look at your sentences and ask yourself: Can I communicate the same thought in fewer words? Is every sentence or phrase necessary?',
    },
    {
      id: 'panel4',
      title: 'Limit prepositions.',
      content:
        'Keep your sentences short by limiting prepositions. We use prepositions such as at, to, of, with, and by to show how two words relate. Sentences that contain several prepositions can appear unnecessarily long and complicated. If you spot too many prepositions, try rephrasing your work. For example, instead of saying “try to limit,” you could write “try limiting.” Or, instead of saying “this leads to,” you could write “this creates.”',
    },
    {
      id: 'panel5',
      title: 'Make a clear call to action.',
      content:
        'If you need your audience to take an action, such as to review a proposal or make a project update, then be direct. State your request clearly, and set expectations for when you need the action completed by and how they should follow up with you. For example, you might write, “Please review my notes and make the updates by the end of Friday, June 28. Email me when you’re done so that I can forward it from there.”',
    },
  ];
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">Improving Your Business Writing Skills</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <p className="text-2xl text-center leading-10 mt-16 font-light mb-16">
          In the workplace, solid writing skills can play a significant role in your career success.
          Whether you’re catching up with a coworker, pitching an idea to your boss, or building a
          relationship with a new client, the quality of your writing can make the difference
          between a positive and negative impression.
          <br />
          <br />
          So, how do you avoid disorganized, confusing, and ambiguous writing? How do you keep your
          work focused, clear, and relatable? And what separates passable writing from excellent
          writing?
        </p>
        <p className="mb-8">
          These are the questions we’ll answer in this lesson. We’ll also lay out a step-by-step
          process you can use to organize your writing. Then, we’ll outline four expert tips you can
          follow to improve your writing.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls controlsList="nodownload">
            <source
              src={chapter?.course_video_url && chapter?.course_video_url[0]}
              type="video/mp4"
            />
          </video>
        </div>
        <div className="border rounded-3xl">
          <CarouselComponent data={slidesData} />
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          Improving Your Writing Style
        </h3>
        <p className="mb-8">
          Once your content is organized, it’s time to start writing your piece. Follow these expert
          tips to refine your writing and make sure it flows smoothly:
          <br />
          <br />
          To expand the rows below click the (+) signs to learn more.
        </p>
        {accordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}
        <MediaBlock mediaImage={georgeOrwall}>
          <p className="text-3xl text-center leading-10 font-light lg:w-3/4 mx-auto">
            Never use a long word where a short one will do. If it is possible to cut a word out,
            always cut it out. Never use the passive voice where you can use the active. Never use a
            foreign phrase, a scientific word, or a jargon word if you can think of an everyday
            English equivalent.
            <br />
            <span className="text-base">George Orwell</span>
          </p>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-3">What’s Your Process?</h3>
        <CourseQuestionCard
          submittedAnswer={userCourseQuestionResponseContext[
            chapterQuestions[0].course_id
          ]?.chapters[chapter.id]?.questions?.at(0)}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmitAnswer={handleCourseAnswerSubmit}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetakeQuestion={handleQuestionRetake}
          question={chapterQuestions[0]}
        />
        <p className="text-2xl text-center leading-10 font-light mb-10 mt-5">
          By following this straightforward process, you can improve your business writing.
          <br />
          <br />
          In the following lessons, we'll take a closer look at the steps outlined above.
        </p>

        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full"
        >
          HOW TO WRITE WITH PURPOSE
        </button>
      </div>
    </>
  );
};
