import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useIntakeFormContext } from '../../hooks/use-form-context.hooks';
import { useUserProfileContext } from '../../hooks/user-user-profile-context.hooks';
import { dispatchGetIntakeFormValues } from '../../redux/slice/form-intake/form-intake.operations';
import { dispatchGetUserProfileValues } from '../../redux/slice/user-profile';
import { useAppDispatch } from '../../redux/store';
import { AuthenticatedPage } from '../AuthenticatedPage';

import { DashboardNavbar } from './DashboardNavbar';
import { Footer } from './Footer';

export const DashboardLayout: React.FC = () => {
  const { name } = useIntakeFormContext();
  const userProfileContext = useUserProfileContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(dispatchGetUserProfileValues());
    void dispatch(dispatchGetIntakeFormValues());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [name, userProfileContext]);
  return (
    <>
      <div className="min-h-screen">
        <ToastContainer theme="colored" limit={1} autoClose={2000} position="top-center" />
        <div className="w-full block">
          <DashboardNavbar name={userProfileContext?.name || name || ''} />
          <main>
            <Outlet />
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};
export const DashboardLayoutComponent: React.FC = () => {
  return <AuthenticatedPage render={() => <DashboardLayout />} />;
};
