import { uuidv4 } from '@firebase/util';
import { deleteField, doc, DocumentSnapshot, getDoc, setDoc } from 'firebase/firestore';

import { UserProfileDTO } from '../../redux/slice/user-profile';
import logger from '../logger';

import { getFirestoreDatabase } from './configure';
import { DatabaseTable } from './database.types';
import { getIdForFormRecord } from './database.utils';

const database: DatabaseTable = DatabaseTable.USER_PROFILE;
/**
 * upsert a record into the user profile collection
 */
async function updateRecord<T extends Record<string, unknown>>(dto: T): Promise<void> {
  const recordId: string = await getIdForFormRecord();
  await setDoc(doc(getFirestoreDatabase(), database, recordId), dto, {
    merge: true,
  });
}

/**
 * retrieves previously stored user profile values if available
 */
export async function getUserProfileValues(): Promise<UserProfileDTO> {
  try {
    const recordId: string = await getIdForFormRecord();
    const result: DocumentSnapshot<Partial<UserProfileDTO>> = await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    );
    return (result.data() as UserProfileDTO) ?? {};
  } catch (e) {
    logger.error('Failed to retrieve user profile values.', e);
    throw e;
  }
}

/**
 * updates `undefined` properties in a DTO with firestore's `deleteField`
 *
 * if `undefined` properties are attempted to be set, firestore throws an error
 * this method is used to intentionally unset values
 *
 * @tutorial https://firebase.google.com/docs/firestore/manage-data/delete-data#fields
 */
function replaceUndefinedWithDeleteField<T extends Record<string, unknown>>(dto: T): T {
  const transformedDTO = Object.keys(dto).reduce(
    (updated: Partial<T>, key: string): Partial<T> => ({
      ...updated,
      [key]: dto[key] === undefined ? deleteField() : dto[key],
    }),
    {},
  );
  return transformedDTO as T;
}
/**
 * attempts to find an existing user profile
 */
async function findUserProfile(recordId: string): Promise<UserProfileDTO | undefined> {
  try {
    const result: DocumentSnapshot<Partial<UserProfileDTO>> = await getDoc(
      doc(getFirestoreDatabase(), database, recordId),
    );
    return (result.data() as UserProfileDTO) ?? undefined;
  } catch (e) {
    logger.error('Failed to retrieve pre-employment assessment values.', e);
    throw e;
  }
}

/**
 * updates the user profile
 */
export async function updateUserProfile(dto: UserProfileDTO): Promise<void> {
  try {
    await updateRecord(dto);
    logger.debug('Saved user profile.');
  } catch (e) {
    logger.error('Failed to update user profile.', e);
    throw e;
  }
}

/**
 * creates or updates a user profile
 */
export async function upsertUserProfile(dto: Partial<UserProfileDTO>): Promise<UserProfileDTO> {
  logger.debug('Upserting user profile.');
  try {
    dto.goals?.map((goal) => {
      return {
        id: goal.id || uuidv4(),
        goal: goal.goal,
      };
    });

    const recordId: string = await getIdForFormRecord();
    const userProfile = await findUserProfile(recordId);
    const transformedDto: UserProfileDTO = replaceUndefinedWithDeleteField({
      email: dto.email || userProfile?.email || '',
      name: dto.name || userProfile?.name || '',
      dateOfBirth: dto.dateOfBirth || userProfile?.dateOfBirth || undefined,
      phone: dto.phone || userProfile?.phone || '',
      phoneCountryCode: dto.phoneCountryCode || userProfile?.phoneCountryCode || '',
      searchingJob: dto.searchingJob || '',
      zip: dto.zip || userProfile?.zip || '',
      profileImagePath: dto.profileImagePath || userProfile?.profileImagePath,
      sharedWithHiringManager:
        dto.sharedWithHiringManager || userProfile?.sharedWithHiringManager || false,
      goals: dto.goals || [],
      asaUserId: dto.asaUserId || userProfile?.asaUserId,
      isPremium: dto.isPremium || userProfile?.isPremium || false,
      subscriptionExpiryDate: dto.subscriptionExpiryDate || userProfile?.subscriptionExpiryDate,
      paymentProcessing:
        dto.paymentProcessing == undefined
          ? userProfile?.paymentProcessing ?? false
          : dto.paymentProcessing,
      paymentSessionCreateDate:
        dto.paymentSessionCreateDate == undefined
          ? userProfile?.paymentSessionCreateDate
          : dto.paymentSessionCreateDate,
      stripeSessionId: dto.stripeSessionId || userProfile?.stripeSessionId,
      isMFAEnabled: dto.isMFAEnabled == undefined ? userProfile?.isMFAEnabled : dto.isMFAEnabled,
      subscriptionId: dto.subscriptionId ? dto.subscriptionId : userProfile?.subscriptionId,
      organizationId: dto.organizationId ? dto?.organizationId : userProfile?.organizationId,
    });
    await updateUserProfile(transformedDto);
    return transformedDto;
  } catch (e) {
    logger.error('Failed to upsert pre-employment assessment.', e);
    throw e;
  }
}
