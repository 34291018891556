import _ from 'lodash';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import m1 from '../../../../assets/images/courses/emotional-intelligence/chapter1/m1.jpg';
import m2 from '../../../../assets/images/courses/emotional-intelligence/chapter1/m2.jpg';
import m3 from '../../../../assets/images/courses/emotional-intelligence/chapter1/m3.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import { course_sorting_matching_questions } from '../../../../courses/emotional-intelligence/emotional-intelligence-course-question';
import { ShortingMatchingBase } from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import { dispatchUpdateUserCourseQuestionResponse } from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CustomAccordion } from '../../../common/Accordion';
import { MatchingQuestionBlock } from '../../MatchingQuestion/CourseMatchingQuestion';
import { MediaBlock } from '../../MediaBlock';
import { SimpleCarouselComponent } from '../../SimpleCarouselComponent';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type MatchingAnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};
export const EmotionalIntelligence1: React.FC<ChapterProps> = ({
  chapter,
  totalPages,
  onNextChapter,
}) => {
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);

  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const chapterMatchingQuestions = course_sorting_matching_questions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };

  const courseMatchingQuestion = course_sorting_matching_questions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const PersonalCompetenceAccordionData = [
    {
      id: 'panel1',
      title: 'Self-Awareness',
      content:
        '<p>Self-awareness is <em>recognizing </em>your emotions as they happen, understanding the reason behind them, and evaluating how your thoughts and behaviors are affected.&nbsp;</p><p><br></p><p>Those with high self-awareness are skeptical of their impulse feelings and dig deeper. For example, if you feel angry, you might analyze that emotion to determine if it’s masking something like insecurity, sadness, or stress.</p>',
    },
    {
      id: 'panel2',
      title: 'Self-Regulation',
      content:
        '<p>Self-regulation is how you <em>handle</em> your emotions. It requires self-control and thinking before you act.&nbsp;</p><p><br></p><p>Those who are skilled at self-regulation manage impulsive behaviors, control destructive emotions, and maintain resilience during difficult times.</p>',
    },
    {
      id: 'panel3',
      title: 'Self-Motivation',
      content:
        '<p>Finally, self-motivation is your personal <em>drive</em>. It’s using your emotions as a mobilizing tool to ensure that you stick with your goals and avoid impeding behaviors like procrastination.&nbsp;</p><p><br></p><p>The higher your self-motivation, the less reliant you are on external rewards. You have an internal drive that gets you to follow through with objectives, work through obstacles, and remain optimistic.</p>',
    },
  ];
  const SocialCompetenceAccordionData = [
    {
      id: 'panel1',
      title: 'Empathy',
      content:
        '<p>Empathy is <em>understanding</em> another’s emotions. It’s looking outside your perspective and seeing things from someone elses viewpoint.&nbsp;</p><p><br></p><p>Those who are skilled at expressing empathy are highly observant. They notice a person’s inflections, gestures, and verbal and nonverbal cues. They ask questions, discern how people are feeling, and—with that understanding—relate to them.</p>',
    },
    {
      id: 'panel2',
      title: 'Social Skills ',
      content:
        '<p>Social skills are how you <em>interact</em> with others. This competency is about action. It’s taking your understanding of another’s feelings and using that knowledge to create positive exchanges.&nbsp;</p><p><br></p><p>Social skills cover a range of interpersonal skills, including persuading, communicating, influencing, inspiring, negotiating, and managing. Simply put, social skills are “people skills.”</p>',
    },
  ];
  const slidesData = [
    {
      heading: 'Objective Decisions',
      content: `<div class="fr-view"><p>Emotion-filled decisions are often shortsighted and risky. For example, if you’re too excited or prideful, you might fail to realize when something isn’t working.&nbsp;</p><p>If you can recognize your feelings, however, you’ll know when emotions are getting in the way of your actions. And, you can then take a step back and make more rational choices based on objective truths.</p></div>`,
    },
    {
      heading: 'Fewer Information Gaps',
      content: `<div class="fr-view"><p>When you’re emotionally intelligent, you’ll be more aware of your internal and external reality.&nbsp;</p><ul><li aria-level="1"><p>When it comes to yourself, you’ll think before acting, know your strengths and weaknesses, and be less likely to jump to false conclusions.</p></li><li><p>When it comes to others, you’ll notice when people are struggling or feeling overwhelmed, and you’ll know how to support them. </p></li></ul></div>`,
    },
    {
      heading: 'Effective Communication',
      content: `<div class="fr-view"><p>With high emotional intelligence, you’ll have control over your emotions when communicating messages—making you easier to understand. With heightened observational skills, you’ll also gauge a listener’s comprehension and—if necessary—know how to refine your wording.</p><p>What’s more, when listening to others, you’ll recognize where they’re coming from and build strong relationships.</p></div>`,
    },
    {
      heading: 'Improved Workplace Morale',
      content: `<div class="fr-view"><p>Those with emotional intelligence skills can better gauge how others are feeling and address those emotions with kindness and warmth.</p><p>You’ll understand the nuances of various situations—finding a balance between serious work and lighthearted humor. Your emotional intelligence will bring a sense of fun and positive energy into the workplace that boosts morale.</p></div>`,
    },
  ];
  const handleMatchingRetake = async (questionId: string) => {
    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[chapterMatchingQuestions[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterMatchingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              matchingQuestions: [
                ...userMatchingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(questionId),
                ),
              ],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleMatchingExcerciseCompleted = async (matchingAnswers: MatchingAnswersState) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterMatchingQuestions[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterMatchingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              matchingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(Object.keys(matchingAnswers)[0]),
                ),
                matchingAnswers,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  useEffect(() => {
    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[chapterMatchingQuestions[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions;
    if (
      Object.keys(userCourseQuestionResponseContext).length &&
      userMatchingAnswerResponse?.length
    ) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext, isPageDirty]);
  return (
    <>
      <div className="content-wrap border-l-0 lg:border-l pl-0 lg:pl-8 pt-8 pb-10">
        <h2 className="font-semibold text-3xl">What Is Emotional Intelligence?</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-5">What's Your Reaction?</h3>
        <div>
          <p>When you’re stressed, aggravated, or overwhelmed, how do you typically react?</p>
          <br />
          <p>
            <label className="custom-checkbox">
              <strong>I panic.&nbsp;</strong>If my emotions are running high, I feel anxious and
              unnerved.&nbsp;
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </p>
          <br />
          <p>
            <label className="custom-checkbox">
              <strong>I calm myself down. </strong>I take a deep breath and practice self-calming
              techniques.
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </p>
          <br />
          <p>
            Those who lead with emotional intelligence are skilled at understanding and managing
            their emotions <em>and</em> those of others. The problem—of course—is that emotions are
            tricky. And if you struggle to check off that second “<em>I Calm Myself Down”</em> box,
            you’re not alone. So, what’s the solution?
          </p>
        </div>
        <p className="text-3xl my-8 leading-10 font-light text-center">
          In this lesson, you’ll learn to develop your emotional intelligence by starting with
          basics: defining emotional intelligence and explaining why it’s important.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls controlsList="nodownload">
            <source
              src={chapter?.course_video_url && chapter?.course_video_url[0]}
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">
          What Is Emotional Intelligence?
        </h3>
        <p>
          Emotional intelligence—sometimes shortened to EQ—measures your ability to identify,
          understand, and manage your emotions and the emotions of others. We can divide this
          concept into two categories:
        </p>
        <ul className="list-decimal pl-5 mt-7">
          <li className="mb-7 marker:font-bold pl-5">Personal competence</li>
          <li className="mb-7 marker:font-bold pl-5">Social competence</li>
        </ul>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">1. Personal Competence</h3>
        <p>
          Personal competence is how well you recognize and manage your emotions. It covers three
          elements: self-awareness, self-regulation, and self-motivation.{' '}
        </p>
        <br />
        <p className="mb-7">Expand the rows below by clicking on the (+) signs to learn more.</p>
        {PersonalCompetenceAccordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}
        <MediaBlock mediaImage={m1}>
          <p className="text-3xl leading-10 font-light w-3/4">
            Those with high emotional intelligence tune into their feelings, confront them, and
            regulate their reactions—regardless of how much pressure they might be under.
          </p>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">2. Social Competence</h3>
        <p>
          Social competence is how well you recognize and manage others' emotions. The more adept
          your social competence, the more likely you are to build strong relationships and create
          meaningful interactions.
        </p>
        <br />
        <p className="mb-7">
          Expand the rows below by clicking on the (+) signs to learn about the two elements of
          social competence.
        </p>
        {SocialCompetenceAccordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}
        <MediaBlock mediaImage={m2}>
          <p className="text-3xl leading-10 font-light w-3/4">
            With a solid foundation in self-understanding, those with high emotional intelligence
            are adept at spotting, understanding, and responding to the feelings of others.
          </p>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">
          Why Emotional Intelligence?{' '}
        </h3>

        <p>
          Workplace success spans more than just intellectual smarts. Top employees are also adept
          at managing their reactions, gauging others’ needs, and responding appropriately—and
          that’s what emotional intelligence is all about.
        </p>
        <br />
        <p>
          Move through the interaction below by clicking on the arrows to learn how emotional
          intelligence leads to success.
        </p>
        <div className="border rounded-3xl pt-10 mt-7">
          <SimpleCarouselComponent data={slidesData} />
        </div>
        <p className="text-3xl my-10 leading-10 font-light text-center">
          "If your emotional abilities aren't in hand, if you don't have self-awareness, if you are
          not able to manage your distressing emotions, if you can't have empathy and have effective
          relationships, then no matter how smart you are, you are not going to get very far."
          <br />
          <span className="text-base font-bold">Daniel Goleman</span>
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">Check Your Understanding</h3>
        <p>
          Did you catch all that? Before moving forward, check your understanding of the various
          emotional intelligence competencies.
        </p>
        {/* Drag and drop */}
        <div className="border rounded-3xl p-8 text-lg mt-3">
          <div className="border-b-2  mb-6">
            <p className=" mb-6">
              Take a look at the five competencies below. Can you match each skill on the left with
              the correct corresponding action on the right?
            </p>
          </div>
          <DndProvider backend={HTML5Backend}>
            <MatchingQuestionBlock
              questionData={courseMatchingQuestion[0]}
              submitedAnswer={
                _.flatMap(
                  userCourseQuestionResponseContext[
                    chapterMatchingQuestions[0].course_id
                  ]?.chapters[chapter.id]?.matchingQuestions?.filter((x) =>
                    Object.keys(x).filter((key) => key === chapterMatchingQuestions[0].id),
                  ),
                  _.values,
                )[0]
              }
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onRetake={handleMatchingRetake}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              submitMatchingQuestion={handleMatchingExcerciseCompleted}
            />
          </DndProvider>
        </div>
        <MediaBlock mediaImage={m3}>
          <p className="text-3xl leading-10 font-light w-3/4">
            <span className="font-bold">EQ starts with you.</span> Emotional intelligence involves
            several components, but they all start with you. By building your self-awareness, you’ll
            recognize similar emotions in others. By learning self-motivation, you’ll develop
            strategies for motivating a team.
          </p>
        </MediaBlock>
        <p className="text-3xl my-10 leading-10 font-light text-center">
          Understanding your feelings and reactions will improve your ability to understand and
          influence others. In the next lesson, you’ll learn how to cultivate your emotional
          intelligence by improving your self-awareness.
        </p>
        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full uppercase"
        >
          Improving Self-Awareness
        </button>
      </div>
    </>
  );
};
