import { useEffect, useState } from 'react';

import { course_questions as courseQuestions } from '../../../../courses/communication-basics/communication-basics.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseQuestionAnswer,
  QuestionOption,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  dispatchUpdateUserCourseQuestionResponse,
  UserChapterQuestion,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CustomAccordion } from '../../../common/Accordion';
import { CourseQuestionCard } from '../../CourseQuestionCard';
import { InfoBlock } from '../../InfoBlock';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};
export const CommunicationBasicsChapter1: React.FC<ChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const dispatch = useAppDispatch();
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const [isPageDirty, setIsPageDirty] = useState(true);

  const [, setAnswers] = useState<AnswersState>({});
  const chapterQuestions = courseQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };

  useEffect(() => {
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];
    if (Object.keys(userCourseQuestionResponseContext).length && userAnswerResponse?.length) {
      const userAnswers: AnswersState = {};
      userAnswerResponse.map((userAnswer) => {
        userAnswers[userAnswer.question_id || ''] = {
          chapter_id: chapter.id,
          question_id: userAnswer.question_id || '',
          question: userAnswer.question || '',
          is_correct: userAnswer.isCorrect || false,
          answer_options: userAnswer.answers || [],
        };
      });

      setAnswers((prevAnswers) => {
        return {
          ...prevAnswers,
          ...userAnswers,
        };
      });
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);
  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
  };

  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const accordionData = [
    {
      id: 'panel1',
      title: 'Verbal',
      content:
        '<span class="font-bold">Speak slowly and confidently.</span> Help your listeners understand your message by speaking slowly and clearly pronouncing your words. This is your message, so be confident and speak loud and clear.<br /><br /> <span class="font-bold">Stay on topic.</span> It’s easy to get off track when communicating verbally. If you’re not careful, you may find yourself questioning: “What was I saying?” Help your listeners leave with the right takeaway by avoiding tangents and delivering a straightforward message.<br /><br /> <span class="font-bold">Pay attention to your tone of voice.</span> Your meaning will change depending on your volume, inflection, and sound. Note the difference between: “She was going to Tom’s house,” and “She WAS going to Tom’s house.” Your tone should match your message. That will help you to highlight the right points.',
    },
    {
      id: 'panel2',
      title: 'Nonverbal',
      content:
        '<span class="font-bold">Be aware of your nonverbal cues.</span> Even if you’re not paying attention, your actions will communicate meaning. Be aware of your gestures, facial expressions, clothing, and body language. Do they align with your message? <br /><br /> <span class="font-bold">Pair your gestures with words.</span> Does shrugging your shoulders mean you’re flexible, or disappointed? Sometimes, our nonverbal communication can be vague. When possible, try pairing your gestures with words to keep your intentions clear.<br /><br /> <span class="font-bold">Practice open, friendly body language.</span> You’ll likely appear closed off if you’re hunched over, looking down, and crossing your arms. Instead, try sitting up straight and keeping your body language open. That will help communicate attentiveness, interest, and confidence.',
    },
    {
      id: 'panel3',
      title: 'Written',
      content:
        '<span class="font-bold">Plan before you write.</span> By planning out your message before you start writing, your communication will likely be more structured and easy to understand. <br /><br /> <span class="font-bold">Keep your message clear and concise.</span> What’s the point of this message? If readers can’t answer that question after a few sentences, they might stop reading. You’ll be more effective if your messages are concise and easy to follow.<br /><br /> <span class="font-bold">Use proper grammar, spelling, and punctuation.</span> Poor grammar and spelling can quickly ruin the credibility of your written messages. Don’t forget to proofread your work before sharing it with others.',
    },
  ];

  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">
          How to Use Verbal, Nonverbal, and Written Communication
        </h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <p className="text-2xl text-center leading-10 mt-16 font-light mb-20">
          To become a more skilled communicator, you'll need to learn to use verbal, nonverbal, and
          written communication effectively. In short, when your{' '}
          <span className="font-bold">spoken words, visual cues</span>, and{' '}
          <span className="font-bold">written words</span> are easy to understand, it’s more likely
          your messages will be <span className="font-bold">heard, seen,</span> and{' '}
          <span className="font-bold">read.</span>
        </p>
        <p>
          In this lesson, we’ll define and give examples of the three types of communication:
          verbal, nonverbal, and written. Then, we’ll show you how to use each of these three types
          of communication most effectively.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls controlsList="nodownload">
            <source
              src={chapter?.course_video_url && chapter?.course_video_url[0]}
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          The Three Types of Communication
        </h3>
        <p>There are three types of communication: verbal, nonverbal, and written.</p>
        <ul className="list-decimal pl-7 mt-8 mb-14 text-xl font-light">
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Verbal communication. </span> Verbal messages are spoken
            aloud and communicate meaning when heard. When communicating verbally, you’ll use your
            words and tone of voice to share information with others. Examples of verbal
            communication include phone and video calls and in-person conversations.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Nonverbal communication.</span> Nonverbal messages are
            wordless. These messages communicate meaning when seen. Examples of nonverbal
            communication include your body language, facial expressions, physical appearance, and
            actions.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Written communication.</span> Written messages are
            text-based and communicate meaning when read. Examples of written communication include
            emails, texts, instant messages, memos, proposals, and letters.
          </li>
        </ul>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          How to Use the Three Types of Communication Effectively
        </h3>
        <p className="mb-9">
          You can become a more skilled communicator by learning how to use verbal, nonverbal, and
          written communication effectively. Expand the rows below by clicking the (+) signs to
          learn more.
        </p>
        {accordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}
        <div className="bg-purple-100  rounded-3xl p-6 mt-10">
          <h4 className="text-xl text-purple-600 font-bold mb-2">
            Effective Communication in Action
          </h4>
          <p>
            Take a look at the examples below. Let’s see how each of these messages could be shared
            more effectively.
          </p>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">Verbal</h3>
        <p>
          <span className="font-bold">Speaking Slowly and Clearly.</span> How might you hear the
          message: “Hithankyoufor returningmycall”? When messages are spoken too quickly or too
          quietly, we’re likely to miss valuable details. Consider how this message can be vocalized
          more effectively if it’s spoken slowly and confidently: “Hi, thank you for returning my
          call.”
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">Nonverbal</h3>
        <p>
          <span className="font-bold">Be Aware of Your Nonverbal Cues.</span> A promising job
          candidate shows up for an interview, but he’s hunched over, his arms are crossed, and his
          clothes are wrinkled. Think about how you might have received this candidate differently
          if he wore a clean, pressed suit, sat up straight, and used open, friendly gestures.
          call.”
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">Written</h3>
        <p>
          <span className="font-bold">Grammar, Spelling, and Punctuation.</span> “It is great meetin
          you today at ours offfice. I look fowrd to doing buisness wit you in the future.” Even if
          you had a great meeting with this person, they’d probably ruin their credibility if they
          sent an email riddled with errors. Let’s try that again: “It was great meeting you today
          at our office. I look forward to doing business with you in the future.” call.”
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-3">What Went Wrong?</h3>
        <p>
          Based on what you’ve learned, can you spot what went wrong in the following three
          situations?
        </p>
        <div className="pt-8">
          <CourseQuestionCard
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetakeQuestion={handleQuestionRetake}
            submittedAnswer={userCourseQuestionResponseContext[
              chapterQuestions[0].course_id
            ]?.chapters[chapter.id]?.questions?.find(
              (x) => x.question_id === chapterQuestions[0].id,
            )}
            label="Question 1 of 3"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmitAnswer={handleCourseAnswerSubmit}
            question={chapterQuestions[0]}
          />
        </div>

        <div className="pt-8">
          <CourseQuestionCard
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetakeQuestion={handleQuestionRetake}
            submittedAnswer={userCourseQuestionResponseContext[
              chapterQuestions[0].course_id
            ]?.chapters[chapter.id]?.questions?.find(
              (x) => x.question_id === chapterQuestions[1].id,
            )}
            label="Question 2 of 3"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmitAnswer={handleCourseAnswerSubmit}
            question={chapterQuestions[1]}
          />
        </div>

        <div className="pt-8">
          <CourseQuestionCard
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetakeQuestion={handleQuestionRetake}
            submittedAnswer={userCourseQuestionResponseContext[
              chapterQuestions[0].course_id
            ]?.chapters[chapter.id]?.questions?.find(
              (x) => x.question_id === chapterQuestions[2].id,
            )}
            label="Question 3 of 3"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmitAnswer={handleCourseAnswerSubmit}
            question={chapterQuestions[2]}
          />
        </div>

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">Summary</h3>
        <p className="mb-8">
          Let’s review. There are three types of communication: verbal, nonverbal, and written.
        </p>
        <ul className="list-disc pl-5 mb-7">
          <li className="mb-5">
            <span className="font-bold">Verbal:</span> spoken messages that communicate meaning when
            heard.
          </li>
          <li className="mb-5">
            <span className="font-bold">Nonverbal:</span> wordless messages that communicate meaning
            when seen.
          </li>
          <li className="mb-5">
            <span className="font-bold">Written:</span> text-based messages that communicate meaning
            when read.
          </li>
        </ul>
        <p className="mb-7">
          By using these three types of communication more effectively, you can become a more
          skilled communicator. Here’s a helpful checklist of questions to ask yourself:
        </p>
        <ul className="list-disc pl-5 mb-7">
          <li className="mb-5">
            <span className="font-bold">Verbal:</span> Am I speaking slowly and confidently? Am I
            staying on topic? Does my tone of voice match my message?
          </li>
          <li className="mb-5">
            <span className="font-bold">Nonverbal:</span> Does my body language and appearance match
            my message? Am I pairing my nonverbal cues with spoken words? Are my gestures open and
            friendly?
          </li>
          <li className="mb-5">
            <span className="font-bold">Written:</span> Did I plan out my writing? Is my message
            clear and concise? Did I proofread my work for correct grammar, spelling, and
            punctuation?
          </li>
        </ul>
        <InfoBlock
          title="Nonverbal communication: practice makes perfect. "
          text="Even when we’re silent, our nonverbal cues can speak volumes. If you have an important presentation or conversation coming up, try practicing in front of a mirror or recording yourself. That can help you become more aware of your nonverbal communication."
        />
        <p className="text-2xl leading-10 text-center font-light mb-9">
          Now that you have a basic understanding of the three types of communication, let's take a
          closer look at verbal communication.
        </p>
        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full"
        >
          IMPROVING YOUR VERBAL COMMUNICATION SKILLS
        </button>
      </div>
    </>
  );
};
