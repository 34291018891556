/* eslint-disable @typescript-eslint/no-explicit-any */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Grid,
  Typography,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';

import { formatTime } from '../../utils/activityTimer';
import { CircularLoader } from '../loader/CircularLoader';

interface GenericDataTableProps {
  isLoading: boolean;
  columns: any[]; // Table columns
  data: any[];
  filterFields?: { name: string; label: string }[];
  onFilterChange?: (filters: Record<string, string>) => void;
  ctaLoader?: boolean;
  isCourseTable?: boolean;
  isFilterRequired?: boolean;
}

export const GenericDataTable: React.FC<GenericDataTableProps> = ({
  isLoading,
  columns,
  data,
  filterFields = [],
  onFilterChange,
  ctaLoader,
  isCourseTable = false,
  isFilterRequired = true,
}) => {
  const [filters, setFilters] = useState<Record<string, string | Date | null>>({});
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    rowsPerPage: 10,
  });
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(
    null,
  );

  // Handle filter input changes for general text fields
  const handleFilterChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilters = { ...filters, [field]: event.target.value.toLowerCase() };
    setFilters(newFilters);
    if (onFilterChange) {
      const formattedFilters = Object.keys(newFilters).reduce<Record<string, string>>(
        (acc, key) => {
          const value = newFilters[key];
          if (value instanceof Date) {
            acc[key] = value.toISOString(); // Convert Date to string
          } else {
            acc[key] = value ? String(value) : ''; // Ensure all values are strings, handle null
          }
          return acc;
        },
        {},
      );
      onFilterChange(formattedFilters); // Pass filter changes as strings to parent
    }
  };

  // Sorting function
  const handleSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'asc' &&
      sortConfig.key !== 'action'
    ) {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Function to sort data based on current sortConfig
  const sortedData = React.useMemo(() => {
    if (sortConfig !== null) {
      return [...data].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return data;
  }, [data, sortConfig]);

  // Slice the data for pagination
  const paginatedData = sortedData.slice(
    paginationModel.page * paginationModel.rowsPerPage,
    paginationModel.page * paginationModel.rowsPerPage + paginationModel.rowsPerPage,
  );

  return (
    <Paper className="p-4">
      {/* Filter Fields */}
      <Grid
        display={!isFilterRequired ? 'none' : 'inline-flex'}
        container
        spacing={2}
        className=" w-full p-4"
      >
        {filterFields.map((filterField) => (
          <Grid item xs={12} md={6} key={filterField.name}>
            <TextField
              label={filterField.label}
              variant="outlined"
              fullWidth
              value={filters[filterField.name] || ''}
              onChange={handleFilterChange(filterField.name)}
              className="bg-white rounded-lg"
            />
          </Grid>
        ))}
      </Grid>

      <div className="w-full p-4">
        <TableContainer>
          <Table>
            <TableHead>
              {isCourseTable && (
                <TableRow>
                  <TableCell colSpan={2}></TableCell> {/* Empty cells for non-module columns */}
                  <TableCell colSpan={5} align="center" className="module-header module1-bg">
                    Effective communication and listening skills
                  </TableCell>
                  <TableCell colSpan={5} align="center" className="module-header module2-bg">
                    Understanding self and others
                  </TableCell>
                  <TableCell></TableCell> {/* Empty cells for non-module columns */}
                </TableRow>
              )}
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align="left"
                    className={`font-semibold ${
                      index === 0 ? 'sticky-first-column ' : `module-header`
                    }`}
                    onClick={() =>
                      column.accessor !== 'action' ? handleSort(column.accessor) : null
                    }
                    style={{
                      cursor: column.accessor !== 'action' ? 'pointer' : 'default',
                    }}
                  >
                    {column.label}
                    {sortConfig?.key === column.accessor ? (
                      sortConfig?.direction === 'asc' ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {ctaLoader && <CircularLoader />}
              {isLoading ? (
                <TableRow className="m-1.5">
                  <TableCell colSpan={columns.length} align="center">
                    <CircularProgress color="primary" />
                  </TableCell>
                </TableRow>
              ) : paginatedData.length > 0 ? (
                paginatedData.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        className={colIndex === 0 ? 'sticky-first-column' : 'data-cell'}
                      >
                        {(column.accessor === 'createdAt' ||
                          column.accessor === 'lastLoggedInDate' ||
                          column.accessor === 'endDate') &&
                        row[column.accessor]
                          ? dayjs(row[column.accessor]).isValid()
                            ? dayjs(row[column.accessor]).format('MMM-DD-YYYY')
                            : '--'
                          : column.accessor === 'timeSpentInSeconds'
                          ? formatTime(row[column.accessor])
                          : column.accessor === 'module1PreScore'
                          ? row.module1PreScore || 0
                          : column.accessor === 'module1PostScore'
                          ? row.module1PostScore || 0
                          : column.accessor === 'module2PreScore'
                          ? row.module2PreScore || 0
                          : column.accessor === 'module2PostScore'
                          ? row.module2PostScore || 0
                          : column.Cell
                          ? column.Cell(row)
                          : row[column.accessor] || 0}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <Typography align="center" variant="body1">
                      No data available
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* Pagination */}
      <TablePagination
        component="div"
        count={data.length}
        page={paginationModel.page}
        onPageChange={(_, newPage) => setPaginationModel({ ...paginationModel, page: newPage })}
        rowsPerPage={paginationModel.rowsPerPage}
        onRowsPerPageChange={(event) =>
          setPaginationModel({
            ...paginationModel,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0, // Reset to first page when changing rows per page
          })
        }
        rowsPerPageOptions={[5, 10, 20]}
      />
    </Paper>
  );
};
