export enum StatusType {
  ACTIVE = 'active',
  INACTIVE = 'pending',
  EXPIRED = 'expired',
  FAILED = 'failed',
  UNPAID = 'unpaid',
}
export type OrganizationSubscriptions = {
  [subscriptionId: string]: Subscriptions;
};

export type Subscriptions = {
  totalRegistrationCount: number;
  userCount: number;
  transactions: SubscriptionTransactions[];
  startDate: string;
  endDate: string;
  status: string;
};

export type SubscriptionTransactions = {
  status: string;
  transactionId: string;
  isPaymentProcessing: boolean;
  subscriptionType: string;
  amount: number;
};

export type TransactionMetaData = {
  expires_at: string;
  startDate: string;
  isPaymentProcessing: boolean;
  subscriptionType: string;
  userCount: string;
  veroSessionId: string;
  amount: number;
};
