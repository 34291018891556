import { useEffect, useState } from 'react';

import { course_questions as courseQuestions } from '../../../../courses/communication-basics/communication-basics.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseQuestionAnswer,
  QuestionOption,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  dispatchUpdateUserCourseQuestionResponse,
  UserChapterQuestion,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CustomAccordion } from '../../../common/Accordion';
import { CourseQuestionCard } from '../../CourseQuestionCard';
import { TabsBlock } from '../../TabsBlock';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};
export const CommunicationBasicsChapter8: React.FC<ChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);

  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const [, setAnswers] = useState<AnswersState>({});
  const chapterQuestions = courseQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );

  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };

  useEffect(() => {
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions;
    if (Object.keys(userCourseQuestionResponseContext).length && userAnswerResponse?.length) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);
  const TabsData = [
    {
      title: 'To inform.',
      htmlContent: `An informative message notifies your reader of information. These types of messages are written to share details. Company announcements, business reports, and training manuals are all examples of informative messages. `,
    },
    {
      title: 'To persuade. ',
      htmlContent: `A persuasive message is written to sway your readers and create change or action. Commercials, funding proposals, and permission requests are all types of persuasive messages.`,
    },
    {
      title: 'To convey goodwill. ',
      htmlContent: `A goodwill message uses generous, kind words to help create a closer bond with your readers. Messages such as “Congratulations,” “Thank you for your business,” and “Way to go!” are all examples of writing that conveys goodwill. `,
    },
  ];
  const accordionData = [
    {
      id: 'panel1',
      title: 'Inform',
      content:
        'If you’re writing to inform your reader, you’ll want to state the facts and leave out emotions and opinions.<br /><br />First, your tone should be professional and neutral. That means avoiding slang, colloquialisms, or superlatives (such as “terrific” or “shocking”).<br /><br />Second, your writing style should be direct, concise, and easy to understand. This type of writing gets to the point quickly and uses simple, familiar words. To avoid any misinterpretation or confusion, you may also need to include extra details in your messages.<br /><br />For example, consider how an informative notice gets straight to the point with professional, simple language: “Effective immediately, no employee should use the northeast exit.” To avoid any questions, this notice may then give some additional information, such as: “This change in policy is due to outside construction hazards.”  ',
    },
    {
      id: 'panel2',
      title: 'Persuade',
      content:
        'If you’re writing to persuade your reader, you’ll have two main goals. First, you’ll want to inform your reader of a few important points. Second, you’ll want to convince them to believe in the benefit of that information.<br /><br />When writing persuasive messages, your style and tone should be positive, compelling, and descriptive. With these types of messages, it’s best to focus on the value of your communication and the reason your reader will benefit.<br /><br />For example, consider how apartment listings are often persuasive messages. Instead of informing readers with statements such as, “The apartment has a balcony,” a persuasive message may say, “You can enjoy beautiful views while sitting on a spacious balcony.”<br /><br />If you’re persuading your reader to take action, you can also conclude this type of message with instructions on what your reader should do next. For instance, in the example above, that property listing may end with directions such as, “Call now to learn more.” ',
    },
    {
      id: 'panel3',
      title: 'Goodwill',
      content:
        'If you’re writing a goodwill message, you want to be sincere, selfless, and specific.<br /><br />To keep your writing sincere, you should adopt an informal tone and conversational style. Use everyday words, avoiding business jargon, and including expressive punctuation such as exclamation points. To keep your writing selfless, try focusing solely on the reader. For example, if you’re sending a thank-you note to express goodwill, it’s best not to also include a request for a favor in that same message.<br /><br /> To keep your message specific, be sure to include some colorful details. For instance, rather than saying, “Great work,” a successful goodwill message will explain the “work” being praised: “Your idea of asking followers to share photos was great! It caught on like wildfire and led to many shares. Awesome idea! Well done and thank you!” ',
    },
  ];
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">How to Write with Purpose</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <p className="text-2xl text-center leading-10 mt-16 font-light mb-16">
          In business, we write to communicate. When we first sit down to a blank page or screen, we
          must have a communication goal in mind. We need to ask ourselves:{' '}
          <span className="font-bold">“Why am I writing this?”</span>
          <br />
          <br />
          We need to know <span className="font-bold">why</span> we’re writing and match our style
          and our tone to agree with that motive. We’re then more likely to pass along the
          <span className="font-bold"> right </span>
          information and give our readers the <span className="font-bold">right</span> takeaway.
        </p>
        <p className="mb-8">
          In this lesson, we’ll explore how you can write with purpose. You’ll learn why
          professional communication should have a purpose, how to identify that purpose, and
          finally, how to write your own informative, persuasive, or goodwill message.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls controlsList="nodownload">
            <source
              src={chapter?.course_video_url && chapter?.course_video_url[0]}
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          Why Written Communication Should Have a Purpose
        </h3>
        <p className="mb-8">
          Written communication uses text to deliver messages. Successful writers ask themselves the
          following questions before they begin: “Why am I writing this piece?” and “What is my
          goal?"
          <br />
          Answering those questions provides you a purpose. Instead of writing aimlessly, you’re
          writing mindfully. Instead of filling space on the page, you’re filling those pages with
          meaning.
          <br />
          When your writing has a purpose, you can adjust your style and tone to fit that goal
          and—best of all—make it more likely that you’ll hit your intended mark.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          How to Identify the Purpose of Your Writing
        </h3>
        <p className="mb-8">
          In business, we use written communication for three main purposes: to inform, to persuade,
          or to convey goodwill. Let’s take a look at the list below to learn more about these
          purposes and how to identify which one applies to your message.
        </p>
        <TabsBlock tabsData={TabsData} />
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">How to Write with Purpose</h3>
        <p className="mb-8">
          Depending on your goal you should adjust how you write your message. Expand the rows below
          by clicking the (+) signs to learn how to change your writing style and your tone
          depending on your purpose.
        </p>
        {accordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}

        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-5">
          What Is the Purpose of This Communication?
        </h3>
        <CourseQuestionCard
          submittedAnswer={userCourseQuestionResponseContext[
            chapterQuestions[0].course_id
          ]?.chapters[chapter.id]?.questions?.at(0)}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmitAnswer={handleCourseAnswerSubmit}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetakeQuestion={handleQuestionRetake}
          question={chapterQuestions[0]}
        />

        <p className="text-2xl text-center leading-10 font-light mt-10 mb-10">
          Once you know the purpose of your message, it's time to choose the right medium to
          communicate that message. Continue on to the next lesson for some tips on how to do that.
        </p>

        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full"
        >
          CHOOSING THE RIGHT WRITING MEDIUM
        </button>
      </div>
    </>
  );
};
