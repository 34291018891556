import { MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import character from '../../assets/images/intake/step3/life_stage.svg';
import { useIntakeFormContext } from '../../hooks/use-form-context.hooks';
import { usePreEmploymentAssessmentContext } from '../../hooks/use-pre-employment-assessment.hooks';
import {
  dispatchGetIntakeFormValues,
  dispatchUpdateIntakeFormEducation,
} from '../../redux/slice/form-intake/form-intake.operations';
import {
  EducationLevel,
  IntakeFormEducationDTO,
  State,
  WorkStatus as WorkStatusValue,
} from '../../redux/slice/form-intake/form-intake.types';
import {
  PreEmploymentAssessmentStatus,
  dispatchUpsertPreEmploymentAssessment,
} from '../../redux/slice/pre-employment-assessment';
import { useAppDispatch } from '../../redux/store';
interface FormData {
  education: string;
  workStatus: string[];
  workStatusComments?: string;
  schoolName?: string;
  schoolState?: string;
  schoolCity?: string;
}
interface WorkStatusProps {
  step: number;
  onBack: () => void;
}
const education_options = [
  {
    value: EducationLevel.FRESHMAN,
    label: 'Freshman',
    sub_label: '9th grade',
  },
  {
    value: EducationLevel.SOPHOMORE,
    label: 'Sophomore',
    sub_label: '10th grade',
  },
  {
    value: EducationLevel.JUNIOR,
    label: 'Junior',
    sub_label: '11th grade',
  },
  {
    value: EducationLevel.SENIOR,
    label: 'Senior',
    sub_label: '12th grade',
  },
  {
    value: EducationLevel.SOME_COLLEGE,
    label: 'Some College',
  },
  {
    value: EducationLevel.COLLEGE_GRADUATE,
    label: 'College Graduate',
  },
];

const work_options = [
  {
    value: WorkStatusValue.COLLEGE_TEST_PREP,
    label: 'College Test Prep',
  },
  {
    value: WorkStatusValue.CAREER_COACHING,
    label: 'Career Coaching',
  },
  {
    value: WorkStatusValue.NO_COLLEGE_TEST_PREP,
    label: 'No College Test Prep',
  },
  {
    value: WorkStatusValue.NO_CAREER_COACHING,
    label: 'No Career Coaching',
  },
  {
    value: WorkStatusValue.INTERNSHIP,
    label: 'Internship',
  },
  {
    value: WorkStatusValue.APPRENTICESHIP,
    label: 'Apprenticeship',
  },
  {
    value: WorkStatusValue.BOOTCAMP_CODING,
    label: 'Bootcamp/coding',
  },
  {
    value: WorkStatusValue.ENTREPRENEURSHIP,
    label: 'Entrepreneurship',
  },
  {
    value: WorkStatusValue.COMMUNITY_COLLEGE,
    label: 'Community College',
    sub_label: '2 Year College',
  },
  {
    value: WorkStatusValue.VOCATIONAL,
    label: 'Vocational',
    sub_label: 'Trade School',
  },
  {
    value: WorkStatusValue.PHD,
    label: 'PHD or Law Degree',
  },
  {
    value: WorkStatusValue.POST_GRADUATE,
    label: 'Post Graduate',
    sub_label: 'Graduate School',
  },
  {
    value: WorkStatusValue.UNEMPLOYED,
    label: 'Unemployed',
  },
  {
    value: WorkStatusValue.MILITARY_SERVICE,
    label: 'Military service/schooling/training',
  },
  {
    value: WorkStatusValue.WORK_PART_TIME,
    label: 'Work part time',
    sub_label: 'Casual',
  },
  {
    value: WorkStatusValue.WORK_FULL_TIME,
    label: 'Working full time',
  },
];

export const WorkStatus: React.FC<WorkStatusProps> = ({ step, onBack }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const { education, workStatus, workStatusComments, schoolCity, schoolState, schoolName } =
    useIntakeFormContext();

  useEffect(() => {
    void dispatch(dispatchGetIntakeFormValues());
  }, [education]);
  const {
    progress,
    defaultStepProgress,
    status: preEmploymentAssessmentStatus,
    disablePersonalInfo,
  } = usePreEmploymentAssessmentContext();

  const [selectedEducation, setSelectedEducation] = useState(education || '');
  const [selectSchoolName, setSelectSchoolName] = useState('');
  const [selectSchoolState, setSelectSchoolState] = useState('');
  const [selectSchoolCity, setSelectSchoolCity] = useState('');
  const [otherRemarks, setOtherRemarks] = useState(workStatusComments || '');
  const [selectedWorkStatus, setSelectedWorkStatus] = useState<string[]>(workStatus || []);
  const [showOrganization, setShowOrganization] = useState(false);
  const [error, setError] = useState({
    education: '',
    workStatus: '',
    schoolName: '',
    schoolCity: '',
  });
  const state_options = (Object.keys(State) as Array<keyof typeof State>)
    .filter((key) => typeof State[key] === 'string')
    .map((key) => ({ label: key, value: State[key] }));

  useEffect(() => {
    if (preEmploymentAssessmentStatus === PreEmploymentAssessmentStatus.COMPLETE) {
      setIsDisabled(true);
    }
  }, [preEmploymentAssessmentStatus]);
  const textAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherRemarks(event.target.value);
    if (event.target.value.trim() && !selectedWorkStatus.includes(WorkStatusValue.OTHER)) {
      setSelectedWorkStatus([...selectedWorkStatus, WorkStatusValue.OTHER]);
    } else if (!event.target.value.trim()) {
      setSelectedWorkStatus(selectedWorkStatus.filter((val) => val !== WorkStatusValue.OTHER));
    }
  };
  const handleEducationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedEducation(event.target.value);

    if (
      value === EducationLevel.FRESHMAN ||
      value === EducationLevel.JUNIOR ||
      value === EducationLevel.SENIOR ||
      value === EducationLevel.SOPHOMORE
    ) {
      setShowOrganization(true);
    } else {
      setShowOrganization(false);
    }
    setError({ ...error, education: '' });
  };
  const handleWorkStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (selectedWorkStatus.includes(value)) {
      setSelectedWorkStatus(selectedWorkStatus.filter((val) => val !== value));
    } else {
      setSelectedWorkStatus([...selectedWorkStatus, value]);
      setError({ ...error, workStatus: '' });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll to the top
  }, []);
  useEffect(() => {
    setSelectedEducation(education ?? '');
    setOtherRemarks(workStatusComments ?? '');
    setSelectedWorkStatus(workStatus ?? []);
    if (
      education === EducationLevel.FRESHMAN ||
      education === EducationLevel.JUNIOR ||
      education === EducationLevel.SENIOR ||
      education === EducationLevel.SOPHOMORE
    ) {
      setShowOrganization(true);
      setSelectSchoolCity(schoolCity ?? '');
      setSelectSchoolState(schoolState ?? '');
      setSelectSchoolName(schoolName ?? '');
    }
  }, [education, workStatus, workStatusComments, schoolCity, schoolName, schoolState]);
  const { handleSubmit } = useForm<FormData>();
  const submitWorkStatus: SubmitHandler<FormData> = async () => {
    setIsLoading(true);
    try {
      const isOtherSelected: boolean = selectedWorkStatus.includes(WorkStatusValue.OTHER);
      if (!selectedEducation) {
        setError({ ...error, education: 'Please select an option' });
      } else if (!selectedWorkStatus.length) {
        setError({ ...error, workStatus: 'Please select at least one option.' });
      } else if (!isOtherSelected && otherRemarks?.trim()) {
        setError({ ...error, workStatus: 'Please select the Other(Explain) option.' });
      } else if (isOtherSelected && !otherRemarks?.trim()) {
        setError({ ...error, workStatus: 'Please explain the other category' });
      } else if (showOrganization && selectSchoolName.trim().length === 0 && !disablePersonalInfo) {
        setError({ ...error, schoolName: 'School Name cannot be empty' });
      } else if (showOrganization && selectSchoolCity.trim().length === 0 && !disablePersonalInfo) {
        setError({ ...error, schoolCity: 'School City/Town cannot be empty' });
      } else {
        if (isDisabled) {
          navigate(`/pre-employment/intake/step${step + 1}`);
        } else {
          const dto: IntakeFormEducationDTO = {
            education: selectedEducation as EducationLevel,
            workStatus: selectedWorkStatus as WorkStatusValue[],
            workStatusComments: isOtherSelected ? otherRemarks?.trim() : undefined,
            schoolName: showOrganization ? selectSchoolName.trim() : '',
            schoolCity: showOrganization ? selectSchoolCity.trim() : '',
            schoolState: showOrganization ? selectSchoolState.trim() : '',
          };
          await dispatch(dispatchUpdateIntakeFormEducation(dto));
          navigate(`/pre-employment/intake/step${step + 1}`);
          if (defaultStepProgress) {
            const totalProgress = (progress ?? 0) + defaultStepProgress;
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                progress: education ? progress : totalProgress,
                activeStepUrl: `/pre-employment/intake/step${step + 1}`,
              }),
            );
          } else {
            void dispatch(
              dispatchUpsertPreEmploymentAssessment({
                activeStepUrl: `/pre-employment/intake/step${step + 1}`,
              }),
            );
          }
        }
      }
    } catch {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };
  const backButtonClick = () => {
    onBack();
  };
  useEffect(() => {
    schoolName && setSelectSchoolName(schoolName);
    schoolCity && setSelectSchoolCity(schoolCity);
    schoolState && setSelectSchoolState(schoolState);
  }, [schoolCity, schoolName, schoolState]);
  return (
    <div>
      <div className="character absolute top-96 left-[-210px] z-[-1]">
        <img className="" src={character} alt="" />
      </div>
      <form
        className="md:px-10"
        action=""
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitWorkStatus)}
      >
        <div className="flex mt-9 mb-10">
          <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">03</div>
          <div>
            <h4 className="text-2xl">
              <span className="font-semibold"> Education</span> &/Or
              <span className="font-semibold"> Work Status</span>
            </h4>
            <p className="text-lg">where are you in your education journey is more important</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3">
          {education_options.map((option) => (
            <div key={option.label} className="h-[100px] lg:h-[60px]">
              <label className="radio-container h-full p-4 block relative ">
                <input
                  disabled={isDisabled}
                  checked={selectedEducation === option.value}
                  onChange={handleEducationChange}
                  className="w-full h-full absolute top-0 left-0 opacity-0"
                  type="radio"
                  value={option.value}
                  name="work status"
                />
                <span className="checkmark font-semibold">
                  <span className="mob-content-center">
                    {option.label}
                    {option.sub_label && (
                      <span className="block font-light text-sm">{option.sub_label}</span>
                    )}
                  </span>
                </span>
              </label>
            </div>
          ))}
          {error.education && <p className="text-red-500">{error.education}</p>}
          {/* ...............................school detils............................ */}
        </div>

        {showOrganization && (
          <div>
            <div className="flex items-center mt-9 mb-5 border-t border-purple-700 pt-10">
              <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">
                3.1
              </div>
              <div>
                <p className="text-lg">
                  <span className="font-bold">School Information?</span> Please include name, state
                  and city of your school
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6 ">
              <TextField
                className="col-span-2"
                autoComplete="off"
                id="schoolName1"
                type="text"
                name="schoolName1"
                disabled={isDisabled}
                label="School Name"
                variant="outlined"
                value={selectSchoolName}
                onChange={(e) => setSelectSchoolName(e.target.value)}
                inputProps={{ maxLength: 75 }}
                error={!!error.schoolName}
                helperText={error.schoolName && error.schoolName}
              />
              <TextField
                className=""
                id="outlined-select-state"
                select
                label="State"
                disabled={isDisabled}
                onChange={(e) => setSelectSchoolState(e.target.value)}
                value={selectSchoolState}
                helperText=""
                defaultValue=""
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Set the maximum height of the dropdown
                      },
                    },
                  },
                }}
              >
                <MenuItem key="select" value="">
                  Select
                </MenuItem>
                {state_options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className=""
                id="city"
                type="text"
                value={selectSchoolCity}
                disabled={isDisabled}
                onChange={(e) => setSelectSchoolCity(e.target.value)}
                label="City/Town"
                variant="outlined"
                error={!!error.schoolCity}
                helperText={error.schoolCity && error.schoolCity}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
        )}
        <div className="flex items-center mt-9 mb-10 border-t border-purple-700 pt-10">
          <div className="bg-gray-200 p-4 rounded-lg font-semibold mr-5 flex items-center">04</div>
          <div>
            <p className="text-lg">
              <span className="font-bold">Where are you now?</span> Pick all that you’ve experienced
              before
            </p>
          </div>
        </div>

        <div className="grid grid-col-1 md:grid-cols-2 gap-x-6 gap-y-3">
          {work_options.map((option) => (
            <div key={option.label} className="h-[100px] lg:h-[60px]">
              <label className="checkbox-container h-full p-4 block relative">
                <input
                  disabled={isDisabled}
                  checked={selectedWorkStatus.includes(option.value)}
                  onChange={handleWorkStatusChange}
                  className="w-full h-full absolute top-0 left-0 opacity-0"
                  type="checkbox"
                  value={option.value}
                />
                <span className="checkbox-checkmark font-semibold">
                  <span className="mob-content-center">
                    {option.label}
                    {option.sub_label && (
                      <span className="block font-light text-sm">{option.sub_label}</span>
                    )}
                  </span>
                </span>
              </label>
            </div>
          ))}
        </div>

        <div className="grid mt-3">
          <div className="h-[170px]">
            <label className="checkbox-container h-full p-4 block relative">
              <input
                disabled={isDisabled}
                checked={selectedWorkStatus.includes(WorkStatusValue.OTHER)}
                onChange={handleWorkStatusChange}
                value="other"
                className="w-full h-full absolute top-0 left-0 opacity-0"
                type="checkbox"
              />
              <span className="checkbox-checkmark font-semibold !block h-auto pt-4">
                Other(Explain)
                <textarea
                  disabled={isDisabled}
                  onChange={textAreaChange}
                  value={otherRemarks}
                  rows={2}
                  className="bg-white border rounded-lg w-full resize-none mt-4 font-light p-3"
                  name=""
                ></textarea>
              </span>
            </label>
          </div>
        </div>
        {error.workStatus && <p className="text-red-500">{error.workStatus}</p>}
        <div className="flex justify-end lg:justify-between mt-8">
          <button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(backButtonClick)}
            className="btn-primary"
          >
            Back
          </button>
          <button className="btn-primary ml-5" disabled={isLoading}>
            Next
          </button>
        </div>
      </form>
    </div>
  );
};
