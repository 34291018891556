import _ from 'lodash';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import emotional_chapter6_chatBackground1 from '../../../../assets/images/courses/emotional-intelligence/chapter6/emotional_chapter6_chatBackground1.jpg';
import m1 from '../../../../assets/images/courses/emotional-intelligence/chapter6/m1.jpg';
import m2 from '../../../../assets/images/courses/emotional-intelligence/chapter6/m2.jpg';
import { CourseChapter } from '../../../../courses/courses.types';
import { course_sorting_matching_questions } from '../../../../courses/emotional-intelligence/emotional-intelligence-course-question';
import {
  CourseMatchingQuestion,
  ShortingMatchingBase,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import { dispatchUpdateUserCourseQuestionResponse } from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CustomAccordion } from '../../../common/Accordion';
import { InfoBlock } from '../../InfoBlock';
import { MatchingQuestionBlock } from '../../MatchingQuestion/CourseMatchingQuestion';
import { MediaBlock } from '../../MediaBlock';
import { SimpleCarouselComponent } from '../../SimpleCarouselComponent';

interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type SortingAnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};
export const EmotionalIntelligence6: React.FC<ChapterProps> = ({
  chapter,
  totalPages,
  onNextChapter,
}) => {
  const [isPageDirty, setIsPageDirty] = useState(true);
  const dispatch = useAppDispatch();
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();

  const courseMatchingQuestion = course_sorting_matching_questions.filter(
    (course) => course.chapter_id === chapter.id,
  );

  const handleMatchingRetake = async (questionId: string) => {
    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[courseMatchingQuestion[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [courseMatchingQuestion[0].course_id]: {
          chapters: {
            [chapter.id]: {
              matchingQuestions: [
                ...userMatchingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(questionId),
                ),
              ],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleMatchingExcerciseCompleted = async (matchingAnswers: SortingAnswersState) => {
    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[courseMatchingQuestion[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [courseMatchingQuestion[0].course_id]: {
          chapters: {
            [chapter.id]: {
              matchingQuestions: [
                ...userMatchingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(Object.keys(matchingAnswers)[0]),
                ),
                matchingAnswers,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const slidesData = [
    {
      heading: 'Communication',
      content: `<div class="fr-view"><p>Under the framework of emotional intelligence, <strong>communication</strong> skills enable you to share your feelings and understand the feelings of others.</p><p>As a vital social skill, effective communication is what allows you to share messages charismatically, listen actively to others, and register a person’s emotional cues accurately.&nbsp;</p></div>`,
    },
    {
      heading: 'Collaboration and Cooperation',
      content: `<div class="fr-view"><p>Social skills and <strong>collaboration</strong> go hand in hand.&nbsp;</p><p>When you’re skilled at recognizing the emotions and needs of others, you can create synergy when pursuing collective goals. You know how to work with a wide range of people, and you can encourage everyone to participate in productive, cooperative discussions.</p></div>`,
    },
    {
      heading: 'Persuasion and Influence',
      content: `<div class="fr-view"><p>Rather than forcing someone to do something, those with strong social skills use <strong>persuasion</strong> and <strong>influence</strong> to win people over to their side.</p><p>With this skill, you can carefully read others, fine-tune your message to appeal to a person’s emotions, and enthuse people so that they want to do what you want.</p></div>`,
    },
    {
      heading: 'Inspirational Leadership',
      content: `<div class="fr-view"><p>Just as you can use your emotional intelligence to motivate yourself, you can also use this competency to <strong>inspire</strong> others, you’ll appeal to other’s feelings with an inspiring vision, motivational words of encouragement, and guided support.</p></div>`,
    },
    {
      heading: 'Conflict Management',
      content: `<div class="fr-view"><p><strong>Conflict management</strong> is the ability to resolve disagreements by creating win-win solutions.&nbsp;</p><p>Those with excellent social skills can settle both personal and work related disputes—bringing conflicts into the open, encouraging free discussions, and resolving debates.</p></div>`,
    },
    {
      heading: 'Developing and Maintaining Relationships',
      content: `<div class="fr-view"><p>Those with robust social skills are adept at <strong>d</strong><strong>eveloping and maintaining relationships</strong>.&nbsp;</p><p>People with this competency express a sincere interest in others, emphasize with other people’s emotions, and build stable bonds through humor, kindness, and non-judgmental communication.</p></div>`,
    },
  ];

  const accordionData = [
    {
      id: 'panel1',
      title: 'Communication Skills',
      content:
        '<div class="fr-view"><p>To enhance your communication skills, you’ll need to share clear messages, listen actively, and elicit information with thoughtful questions.&nbsp;</p><p><br></p><p>When <strong>sharing messages</strong>, be as straightforward as possible. Practice speaking slowly and confidently, pair your words with gestures, and reinforce your messages with the right tone of voice. Also, keep your audience in mind. Are you using easy-to-understand language? Do your listeners seem receptive? <strong>Ask questions&nbsp;</strong>after speaking to ensure that everyone has the same understanding.&nbsp;</p><p><br></p><p><strong>When listening</strong>, avoid distractions, keep an open mind, focus on the speaker, and don’t interrupt. Listen with the intent of not just hearing but <strong>understanding</strong>. And, after your conversation partner has finished speaking, reflect on what you’ve heard. Paraphrase the message to ensure you understand, <strong>ask relevant follow-up questions</strong>, and—if appropriate—offer an emphatic response such as, <em>“I understand how this can be difficult.”</em></p></div>',
    },
    {
      id: 'panel2',
      title: 'Collaboration and Cooperation',
      content:
        '<div class="fr-view"><p>To become a great collaborator and team player, you’ll need to think about the <em>“we”</em> rather than the <em>“I”</em>—and this is where social skills come into play. When working with others, focus on the following points:</p><ul class="list-disc pl-5 mt-7"><li class="mb-4"><p><strong>Clarify roles and responsibilities.&nbsp;</strong>To work well together, team members must share a common understanding of each person’s roles and responsibilities. This clarification will ensure that no one steps on anyone’s toes—thus leading to fewer duplicated efforts and less confusion, frustration, and unfair task delegation.&nbsp;</p></li><li class="mb-4"><p><strong>Define objectives.</strong> It’s easy to get off track when working with others. So, before kicking off a discussion, define the objective. Everyone should be on the same page and looking at the same goalpost.&nbsp;</p></li><li class="mb-4"><p><strong>Celebrate individuality.&nbsp;</strong>Everyone is different, and teams work best when that individuality is celebrated rather than stifled. To improve your success when working within a team, encourage other members to be their authentic selves and celebrate each person’s uniqueness.&nbsp;</p></li><li class="mb-4"><p><strong>Encourage open-minded communication.&nbsp;</strong>Collaboration only works if everyone feels comfortable sharing their thoughts. So, keep an open mind, try to see things from another’s perspective, and encourage others to do the same.</p></li></ul></div>',
    },
    {
      id: 'panel3',
      title: 'Persuasion and Influencing Skills',
      content:
        '<div class="fr-view"><p>To influence and persuade others, you’ll need to tap into a person’s motivations. That way, you can align their desires with your goals and the goals of the company. To develop this social skill, you’ll need to:</p><ul class="list-disc pl-5 mt-7"><li class="mb-4"><p><strong>Formulate a clear argument.&nbsp;</strong>First, what do you want to persuade someone to do? Whether it’s agreeing with a development plan or taking an extra shift at work, decide what your argument is.&nbsp;</p></li><li class="mb-4"><p><strong>Develop a compelling reason.&nbsp;</strong>Then, based on what you know about this person, develop a compelling reason for why they might agree with your argument. When looking for this reason, use your empathy skills to consider the person’s perspective and personal motivators. The best reasons will appeal to their emotions and values.</p></li><li class="mb-4"><p><strong>Practice assertive communication.&nbsp;</strong>Finally, when communicating your argument, practice assertive communication. Speak clearly, sit (or stand) up straight, and deliver your message with conviction.</p></li></ul></div>',
    },
    {
      id: 'panel4',
      title: 'Inspirational Leadership',
      content:
        '<div class="fr-view"><p>Inspirational people give others individualized attention. They recognize the person behind the job, listen to others, and emotionally empower other people. To enhance this social skill, practice the following tips:</p><ul class="list-disc pl-5 mt-7"><li class="mb-4"><p><strong>Communicate a purpose.&nbsp;</strong>Motivated employees are inspired by their work. See your job as more than just a paycheck. To cultivate that inspiration, construct a sense of purpose. What’s your company’s mission, and how can you use that mission to inspire yourself and others?</p></li><li class="mb-4"><p><strong>Appeal to the individual.&nbsp;</strong>Unlock a person’s potential by appealing to their motivators. What do you know about this person? What are their passions? And how can you connect their job to those motivators?</p></li><li class="mb-4"><p><strong>Complement progress.&nbsp;</strong>Inspire others to keep pushing by highlighting their progress. By making before and after comparisons, you’ll show others how you or they are making headway, and you’ll inspire yourself and them to keep going.&nbsp;</p></li><li class="mb-4"><p><strong>Provide autonomy.</strong> Empower others by communicating trust. Rather than constantly looking over a coworker’s shoulders and micromanaging every task, let them know that you have faith in their abilities. Express confidence in someone, and they’ll be more likely to have confidence in you and themselves.</p></li></ul></div>',
    },
    {
      id: 'panel5',
      title: 'Conflict Management Skills',
      content:
        '<div class="fr-view"><p>Those with strong social skills know how to diffuse arguments and resolve conflicts amicably. To develop this emotional competency, practice the following steps:</p><ol class="list-decimal pl-5 mt-7"><li class="mb-4"><p><strong>Identify and deal with emotions</strong>. First, it’s almost impossible to arrive at an amicable solution if people are emotionally overwhelmed. So, deal with emotions first. That might mean agreeing to pause a conversation and “cool down” separately. Or, it could mean coming together and trying to understand empathetically how everyone is feeling before moving forward.</p></li><li class="mb-4"><p><strong>Identify the conflict.&nbsp;</strong>Next, once you’re in an emotionally stable headspace, talk about the conflict. Does everyone agree on <em>what</em> you’re arguing about, or has there been a miscommunication?</p></li><li class="mb-4"><p><strong>Propose solutions.</strong> Now, rather than trying to reprove your points or rehash the issue, shift the conversation so that it’s directed toward a solution. Keeping everyone’s opinion in mind, take turns proposing potential solutions.</p></li><li class="mb-4"><p><strong>End on a cooperative note.&nbsp;</strong>Finally, find a cooperative solution that meets the underlying concerns of everyone involved. Keep in mind that while everyone may not get their first choice, the goal is to reach a conclusion that everyone can accept.</p></li></ol></div>',
    },
    {
      id: 'panel6',
      title: 'Developing and Maintaining Relationships',
      content:
        '<div class="fr-view"><p>To build and maintain strong relationships, focus on making others feel appreciated, understood, and joyful. Here are three useful tips:</p><ul class="list-disc pl-5 mt-7"><li class="mb-4"><p><strong>Demonstrate a sincere interest in others.</strong> Show people that you appreciate them by demonstrating a sincere interest in who they are and what they’re saying. When communicating face-to-face, relax, lean in slightly, nod your head, and make eye contact. Then, to further express your interest, ask open questions that require more than just a <em>“yes” </em>or<em> “no”</em> answer.&nbsp;</p></li><li class="mb-4"><p><strong>Communicate with empathy.</strong> Relationships are often built through similarities. When we meet someone who has the same interests as us or who understands where we’re coming from, we feel connected. So, to build and maintain those connections, make an effort to consider other people’s perspectives. Then, demonstrate your understanding by acknowledging those emotions, giving validation, and offering support. </p></li><li class="mb-4"><p><strong>Inject humor into conversations.</strong> Laughter is a positive emotion, and if you can find ways to make others feel joyful, they’ll likely want to continue a relationship with you. So, keep an eye out for opportunities to make jokes about yourself or the situations you’re in. Just be careful that this humor is never about other people, as that may unintentionally insult the person you’re speaking with.</p></li></ul></div>',
    },
  ];
  useEffect(() => {
    const userMatchingAnswerResponse =
      userCourseQuestionResponseContext[courseMatchingQuestion[0].course_id]?.chapters[chapter.id]
        ?.matchingQuestions;
    if (
      Object.keys(userCourseQuestionResponseContext).length &&
      userMatchingAnswerResponse?.length
    ) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext, isPageDirty]);
  return (
    <>
      <div className="content-wrap border-l-0 lg:border-l pl-0 lg:pl-8 pt-8 pb-10">
        <h2 className="font-semibold text-3xl">Enhancing Social Skills</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-10 mb-5">The Final Piece</h3>
        <p>
          Top workplace performers know how to communicate with others and maintain positive
          relationships. They’re adept at navigating through the shaky waters of conflict and
          change. And they’re skilled at persuading others, boosting cooperation and collaboration,
          and inspiring others.
        </p>

        <p className="text-3xl font-light text-center my-8 leading-10">
          It’s an exhaustive list, but all the above aptitudes fall under the umbrella of social
          skills—the final piece of the emotional intelligence puzzle.
        </p>
        <p>
          Social skills bring emotional intelligence full circle. In this lesson, you’ll learn what
          social skills are, what elements make up this vital competency, and how to enhance your
          social skills in seven steps.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls controlsList="nodownload">
            <source
              src={chapter?.course_video_url && chapter?.course_video_url[0]}
              type="video/mp4"
            />
          </video>
        </div>
        <p>
          Watch the video to learn what social skills are, what elements make up this vital
          competency, and how you can improve your social skills.
        </p>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">What Are Social Skills?</h3>
        <p>
          Social skills are how well you <strong>handle</strong> and <strong>influence</strong>{' '}
          other people’s emotions. This competency is characterized by an ability to:
        </p>
        <ul className="list-disc pl-5 mt-7">
          <li className="mb-6">Tune into another’s emotions</li>
          <li className="mb-6">Sway people in a desired direction</li>
          <li className="mb-6">Manage relationships</li>
        </ul>
        <p>In short, social skills are people skills, and they cover a lot of ground.</p>
        <br />
        <div className="grid grid-cols-2 gap-4">
          <div className="p-5 rounded-3xl border">
            <img className="rounded-3xl" src={m1} alt="" />
            <p className="mt-5">
              For example, social skills can be as simple as knowing how a well-timed joke can
              encourage someone to chuckle.
            </p>
          </div>
          <div className="p-5 rounded-3xl border">
            <img className="rounded-3xl" src={m2} alt="" />
            <p className="mt-5">
              These skills can also go as far as knowing how to encourage a person’s buy-in by
              tapping into their values and motivations.
            </p>
          </div>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">
          6 Hallmarks That Define Social Skills
        </h3>
        <p>
          Social skills cover a wide range of aptitudes that can be overwhelming at first glance. To
          better understand this emotional competency, we can break it down into six hallmarks—all
          of which are beneficial to your workplace success.
        </p>
        <br />
        <p>Move through the interaction below to learn about each hallmark. </p>
        <div className="border rounded-3xl pt-10 mt-7">
          <SimpleCarouselComponent data={slidesData} />
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-7">
          Enhancing Your Social Skills
        </h3>
        <p>
          Social skills are the not-so-secret-ingredient of workplace success. Through practice, you
          can enhance your social skills by improving your aptitude in each of the six social skill
          hallmarks.{' '}
        </p>
        <br />
        <p>
          Expand the rows below by clicking on the (+) signs to learn tips and tricks for boosting
          your success.
        </p>
        <br />
        {accordionData.map((data) => (
          <CustomAccordion
            key={data.title}
            title={data.title}
            content={data.content}
            id={data.id}
          />
        ))}
        <br />
        <InfoBlock
          title=" "
          text="Social skills cover a lot of ground, and it’s not a competency that you can improve overnight. So, don’t get frustrated if you’re struggling. Social skills are best thought of as a long-term project. But that doesn’t mean you can’t start making small improvements every day. Continue to seek opportunities to connect with others and practice the skills covered in this lesson, and you’ll soon become an expert in this vital emotional competency."
        />
        <MediaBlock mediaImage={emotional_chapter6_chatBackground1} />
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-4">
          Match the Skill to the Technique
        </h3>
        <p>
          We covered a lot in this lesson. Check your understanding by completing the following
          matching activity.
        </p>

        <div className="border rounded-3xl p-8 text-lg mb-6 mt-3">
          <div className="border-b-2  mb-6">
            <p className=" mb-6">
              Based on what you’ve learned, can you match each of the social skills below to the
              correct technique?
            </p>
          </div>
          <DndProvider backend={HTML5Backend}>
            <MatchingQuestionBlock
              questionData={courseMatchingQuestion[0] as CourseMatchingQuestion}
              submitedAnswer={
                _.flatMap(
                  userCourseQuestionResponseContext[courseMatchingQuestion[0].course_id]?.chapters[
                    chapter.id
                  ]?.matchingQuestions?.filter((x) =>
                    Object.keys(x).filter((key) => key === courseMatchingQuestion[0].id),
                  ),
                  _.values,
                )[0]
              }
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onRetake={handleMatchingRetake}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              submitMatchingQuestion={handleMatchingExcerciseCompleted}
            />{' '}
          </DndProvider>
        </div>
        <p className="text-3xl my-8 leading-10 font-light text-center">
          Congratulations! Continue to the next lesson for a recap of what you've learned in this
          course.
        </p>
        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full uppercase"
        >
          Summary
        </button>
      </div>
    </>
  );
};
