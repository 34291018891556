//import dragIcon from '../../../../assets/icons/drag.svg';
import { useEffect, useState } from 'react';

import barrier1 from '../../../../assets/images/courses/jumpstart-communicator/chapter6/barrier1.jpeg';
import barrier2 from '../../../../assets/images/courses/jumpstart-communicator/chapter6/barrier2.jpeg';
import introImg from '../../../../assets/images/courses/jumpstart-communicator/chapter6/intro.jpeg';
import { CourseChapter } from '../../../../courses/courses.types';
import { course_questions as courseQuestions } from '../../../../courses/jumpstart-communicator/jumpstart-communicator.quiz';
import {
  CourseQuestionAnswer,
  QuestionOption,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  dispatchUpdateUserCourseQuestionResponse,
  UserChapterQuestion,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CustomAccordion } from '../../../common/Accordion';
import { CourseQuestionCard } from '../../CourseQuestionCard';
import { InfoBlock } from '../../InfoBlock';
import { MediaBlock } from '../../MediaBlock';
import { TabsBlock } from '../../TabsBlock';

interface JumpStartChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}
type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};
export const JumpStartChapter6: React.FC<JumpStartChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);

  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const [, setAnswers] = useState<AnswersState>({});
  const chapterMCQQuestions = courseQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );

  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterMCQQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterMCQQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterMCQQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterMCQQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterMCQQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterMCQQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  useEffect(() => {
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterMCQQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions;
    if (Object.keys(userCourseQuestionResponseContext).length && userAnswerResponse?.length) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);
  const barrierAccordionData = [
    {
      id: 'panel1',
      title: 'Informational',
      content:
        'Providing too little or too much information can create a communication barrier. If we speak in vague terms or leave out essential details, our receivers might not have enough information to understand our messages correctly. If, on the other hand, we give too much information, our receivers may be unable to focus in on any key points or takeaways.',
    },
    {
      id: 'panel2',
      title: 'Language',
      content:
        'When we use abbreviations, slang, technical jargon, or colloquialisms, we risk confusing our receivers. Not only does this often lead to a misinterpretation of our message, but it can also cause our receivers to avoid providing input because they don’t want to appear unknowledgeable.',
    },
    {
      id: 'panel3',
      title: 'Emotional',
      content:
        'When our emotions are heightened—such as when we are stressed, overtired, nervous, sad, or angry—effective communication can become difficult. As receivers, we may be more distracted and prone to selective listening. As senders, we may be unable to convey our thoughts clearly.',
    },
    {
      id: 'panel4',
      title: 'Physical',
      content:
        'Background distractions such as noise, faulty equipment, distance, and even physical discomfort can all disrupt the flow of communication. For example, have you ever struggled to hear instructions shouted at you from a distance? Or, have you ever tried to pay attention to a long presentation in a stuffy meeting room with the temperature set at 80 degrees?',
    },
    {
      id: 'panel5',
      title: 'Perceptional',
      content:
        'Because we all come from different backgrounds, cultures, and experiences, we tend to have different perspectives. These unique viewpoints can cloud our understandings and cause us to interpret the same words and actions differently. For example, while giving a “thumbs up” gesture may be seen as a sign of approval for some, in other cultures that same action is perceived as rude and offensive.',
    },
  ];

  const removeBarrierAccordionData = [
    {
      id: 'panel1',
      title: 'Give Clear, Concise Info',
      content:
        'When explaining something to others, eliminate the guesswork by being straightforward and providing specifics. Or, if you are the receiver, clearly ask the sender for more information by requesting details or examples.<br/><br/>Also, remember that part of being clear means being concise. Avoid long-winded sentences, stay on target, and keep your points focused and brief. If you’re the receiver and the message is too jumbled, try giving a concise prompt to the sender: “What’s the end goal?”',
    },
    {
      id: 'panel2',
      title: 'Use Simple Language',
      content:
        'Your message is less likely to be misinterpreted if you use everyday language that everyone understands. You should avoid abbreviations, slang, or colloquialisms. Instead, choose short, easy words and simplify your language as much as possible. If you’re the receiver and don’t understand the language, try restating what the sender said in simpler terms to make sure you’re on the same page.',
    },
    {
      id: 'panel3',
      title: 'Have Emotional Awareness',
      content:
        'While we can’t always control our feelings, we can be more aware of them. If your emotions are heightened, try stepping away and asking to return to the situation when you’re more stable. Equally, if you recognize that someone else is emotionally charged, try to be sensitive and not force the communication.',
    },
    {
      id: 'panel4',
      title: 'Have Physical Awareness',
      content:
        'If you’re struggling to understand another person (or are being misunderstood yourself), see if you can spot any physical barriers. Are you having trouble hearing this person because the cell service is bad? Try calling the person back later. Are you in a meeting where everyone is fidgeting because they’ve been sitting for three hours straight? Maybe it’s time to take a break.',
    },
    {
      id: 'panel5',
      title: 'Keep an Open Mind',
      content:
        'When you or someone else is sharing information, do your best to remember that you may have different perspectives. Put yourself in the other person’s shoes, try to see things from their viewpoint, and keep an open mind. You’ll then be less likely to offend someone accidentally or jump to the wrong conclusions about what they did or said.',
    },
  ];

  const communicationConfusionTabData = [
    {
      title: 'TOO MUCH INFORMATION',
      htmlContent: `<p className="text-base">
        You received a proposal from a co-worker in another department. You have read the first two pages and are not sure what it’s about. Thus far, you’ve only read about a number of challenges facing the company. This is going somewhere, right?</p>`,
    },
    {
      title: 'ABBREVIATIONS',
      htmlContent: `<p className="text-base"><span className="italic font-bold">“TY. QQ right now over all the RAKs I’ve been seeing. So tonight, we’re celebrating. WTPA?!”</span><br/>While there may be those who understand the above statement, how might those who aren’t fluent in texting lingo receive it?</p>`,
    },
    {
      title: 'EMOTIONAL DISTRACTIONS',
      htmlContent: `<p className="text-base">Before heading into a company conference, you receive a private message from your boss: <span className="italic font-bold">“Later, we need to talk about your performance.” </span><br/>Understandably, you spend the entire meeting feeling anxious and nervous. How well do you think you understood everything your co-workers shared at today’s staff meeting?</p>`,
    },
  ];
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 text-base">
        <h2 className="font-semibold text-3xl">How to Remove Five Common Communication Barriers</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <MediaBlock cssClass="mb-14" mediaImage={introImg}>
          <div className="flex-1">
            <p className="lg:w-3/4 text-2xl leading-10">Imagine the scene below:</p>
          </div>
        </MediaBlock>
        <p className="mb-10">
          It’s the end of the day. You’re feeling stressed and overtired, and rushing to finish that
          last assignment. A friend approaches your desk about another, unrelated project as you’re
          typing away. “Next week we’ll have to get together with the group to work on the big
          chemistry project.”
          <br />
          <br />
          After he walks away, you realize you don’t know what he said. What’s happening? When is
          this happening? What chemistry project? Who is in the group? You don’t want to appear
          ignorant, but you have no idea what to say next.
        </p>
        <p className="text-3xl leading-10 text-center font-light mb-10">
          That is an example of miscommunication in action, and it happens to all of us.
        </p>

        <p>
          We receive or send a message that doesn’t make sense because the information is unclear,
          the language is confusing, we’re emotionally overwhelmed, or there’s something else
          blocking our understanding—such as a background distraction or a difference in
          perspective.
          <br />
          <br />
          When this happens there is usually a communication barrier in the way, and it can lead to
          a lot of confusion. So, how do we remove these barriers? In this lesson, we’ll explore
          that answer, focusing on five of the most common communication barriers and explaining how
          to best avoid and overcome them as both senders and receivers of messages. Click the play
          button to watch the video.
        </p>
        <div className="video-wrap mt-8 mb-12">
          <video className="rounded-3xl" controls controlsList="nodownload">
            <source
              src={chapter?.course_video_url && chapter?.course_video_url[0]}
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">
          5 Common Communication Barriers
        </h3>
        <p className="mb-8">
          What are some of the most common communication barriers that can lead to
          misunderstandings? Explore the list below by clicking on the tabs to learn more:
        </p>
        <div>
          {barrierAccordionData.map((data) => (
            <CustomAccordion
              key={data.title}
              title={data.title}
              content={data.content}
              id={data.id}
            />
          ))}
        </div>
        <MediaBlock cssClass="mb-14" mediaImage={barrier1}>
          <div className="flex-1">
            <p className="lg:w-3/4 text-2xl leading-10">
              Communication Barriers:
              <br />
              Imagine the Impact
            </p>
          </div>
        </MediaBlock>
        <p className="text-2xl leading-10 text-center font-light mb-10">
          Communication barriers can cause a lot of confusion. Consider the examples below by
          clicking on the tabs.
        </p>
        <TabsBlock tabsData={communicationConfusionTabData} />
        <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">
          How to Remove Five Common Communication Barriers
        </h3>
        <p>
          Expand the below rows by clicking on the (+) sign to learn how to avoid and overcome
          common communication barriers whether you’re the sender or the receiver.
        </p>
        <div className="mt-10">
          {removeBarrierAccordionData.map((data) => (
            <CustomAccordion
              key={data.title}
              title={data.title}
              content={data.content}
              id={data.id}
            />
          ))}
        </div>
        <MediaBlock cssClass="mb-14" mediaImage={barrier2}>
          <div>
            <p className="lg:w-3/4 text-2xl leading-10">
              Your assumptions are your windows on the world. Scrub them off every once in a while,
              or the light won't come in.
            </p>
            <p>Isaac Asimov</p>
          </div>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">
          What’s Your Approach to This Communication Barrier?
        </h3>
        <CourseQuestionCard
          submittedAnswer={userCourseQuestionResponseContext[
            chapterMCQQuestions[0].course_id
          ]?.chapters[chapter.id]?.questions?.at(0)}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmitAnswer={handleCourseAnswerSubmit}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetakeQuestion={handleQuestionRetake}
          question={chapterMCQQuestions[0]}
        />

        <div className="text-xl font-light">
          <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">Summary</h3>
          <p>
            Whether you’re the sender or the receiver of a message, there are steps you can take to
            limit miscommunication.
            <br />
            <br />
            Start by thinking about the five most common communication barriers, and ask yourself:
            “Could any of these problems be getting in the way?” If so, you can more easily avoid
            and overcome these barriers by checking in with the following questions:
          </p>
          <ul className="list-decimal pl-7 mt-8 mb-10">
            <li className="mb-8 pl-7 marker:font-bold">
              <span className="font-bold">Informational:</span> Is all the information clear,
              straightforward, and on target? If not, can I clarify anything?
            </li>
            <li className="mb-8 pl-7 marker:font-bold">
              <span className="font-bold">Language:</span> Is the word choice simple and easy to
              understand? Does anything need to be restated?
            </li>
            <li className="mb-8 pl-7 marker:font-bold">
              <span className="font-bold">Emotional:</span> Are emotions heightened? Is now the best
              time to communicate?
            </li>
            <li className="mb-8 pl-7 marker:font-bold">
              <span className="font-bold">Physical:</span> Are there any physical barriers or
              distractions in the way?
            </li>
            <li className="mb-8 pl-7 marker:font-bold">
              <span className="font-bold">Perceptual:</span> Am I considering the other person’s
              perspective and keeping an open mind?
            </li>
          </ul>
        </div>
        <InfoBlock
          title="Just enough information."
          text="What’s the happy medium between too much and too little information? Try this: If you
            could put your entire message into only one sentence, what would you say? Now, give more
            details, but make sure you’re staying on target. Remember, all extra information should
            still relate back to that original sentence."
        />
        <p className="text-2xl leading-10 text-center font-light mb-10">
          You’ve covered some communication fundamentals in this course. Continue to the final
          lesson for a quick summary.
        </p>
        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full"
        >
          SUMMARY
        </button>
      </div>
    </>
  );
};
