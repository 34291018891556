import dayjs from 'dayjs';

import { CustomUser } from '../../redux/slice/auth/CustomUser';
import { EnvironmentVariable } from '../../utils/constants';

const productPrice = Number(process.env[EnvironmentVariable.REACT_APP_SUBSCRIPTION_PRICE_CENTS]);

export const createPaymentSession = async (userData: CustomUser): Promise<object | null> => {
  const response = await fetch(
    `${process.env[EnvironmentVariable.REACT_APP_STRIPE_CREATE_SESSION_URL]}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userEmail: userData.user.email,
        isAsaUser: userData?.claims?.isAsaUser || false,
        userId: userData.user.uid,
        price: productPrice,
        cancelUrl: process.env[EnvironmentVariable.REACT_APP_STRIPE_PAYMENT_FAILED_URL],
        successUrl: process.env[EnvironmentVariable.REACT_APP_STRIPE_PAYMENT_SUCCESS_URL],
        sessionExpiresAt: Math.floor(Date.now() / 1000) + 1900,
      }),
    },
  );
  if (response.ok) {
    const data = await response.json();

    if (data?.data?.url) {
      return { url: data?.data?.url, sessionId: data?.data?.id };
    }
  }
  return null;
};

export const createOrganizationPaymentSession = async (
  userData: CustomUser,
  userCount: number,
  newSessionId: string,
): Promise<object | null> => {
  const response = await fetch(
    `${process.env[EnvironmentVariable.REACT_APP_ORGANIZATION_PAYMENT_URL]}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userEmail: userData.user.email,
        isAsaUser: userData?.claims?.isAsaUser || false,
        userId: userData.user.uid,
        price: productPrice * userCount,
        cancelUrl: `${
          process.env[EnvironmentVariable.REACT_APP_STRIPE_PAYMENT_ORGANIZATION_FAILED_URL]
        }/?subscriptionId=${newSessionId}&organizationId=${
          userData?.claims?.organizationId
        }&productPrice=${productPrice * userCount}`,
        successUrl: `${
          process.env[EnvironmentVariable.REACT_APP_STRIPE_PAYMENT_ORGANIZATION_SUCCESS_URL]
        }/?subscriptionId=${newSessionId}&organizationId=${
          userData?.claims?.organizationId
        }&productPrice=${productPrice * userCount}`,
        sessionExpiresAt: Math.floor(Date.now() / 1000) + 1900,
        startDate: dayjs().utc(),
        sessionId: newSessionId,
        userCount,
        orgId: userData?.claims?.organizationId,
        isPaymentProcessing: true,
        subscriptionType: 'normal',
      }),
    },
  );
  if (response.ok) {
    const data = await response.json();

    if (data?.data?.url) {
      return {
        url: data?.data?.url,
        sessionId: data?.data?.id,
        metaData: data?.data?.metadata,
        amount: data?.data?.amount_total,
      };
    }
  }
  return null;
};

export const getStripPaymentStatus = async (sessionId: string): Promise<object> => {
  const response = await fetch(
    `${process.env[EnvironmentVariable.REACT_APP_TRANSACTION_UPDATE_URL]}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ stripeSessionId: sessionId }),
    },
  );
  return response;
};
