import { useEffect, useState } from 'react';

import ScottPeck from '../../../../assets/images/courses/communication-basics/chapter4/ScottPeck.jpeg';
import { course_questions as courseQuestions } from '../../../../courses/communication-basics/communication-basics.quiz';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  CourseQuestionAnswer,
  QuestionOption,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  dispatchUpdateUserCourseQuestionResponse,
  UserChapterQuestion,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CourseQuestionCard } from '../../CourseQuestionCard';
import { MediaBlock } from '../../MediaBlock';
import { TabsBlock } from '../../TabsBlock';
interface ChapterProps {
  chapter: CourseChapter;
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
}

type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};
export const CommunicationBasicsChapter4: React.FC<ChapterProps> = ({
  chapter,
  onNextChapter,
  totalPages,
}) => {
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);

  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const [, setAnswers] = useState<AnswersState>({});
  const chapterQuestions = courseQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };
  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  useEffect(() => {
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions;
    if (Object.keys(userCourseQuestionResponseContext).length && userAnswerResponse?.length) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);
  const showYoureListeningTabData = [
    {
      title: 'Visibly Show You’re Listening',
      htmlContent: `When meeting face-to-face, you can show you’re listening without saying a word. If you’re slumped back in your chair, looking at the floor, and yawning, the speaker will assume you’re not paying attention. Instead, practice nonverbal behaviors such as nodding, leaning forward, and maintaining eye contact.`,
    },
    {
      title: 'Give Brief and Positive Prompts',
      htmlContent: `<p>Without interrupting, reassure the speaker you’re paying attention by giving brief and positive prompts. A simple <em class="font-bold">“Uh-huh,”&nbsp;</em><em class="font-bold">“Mm-hmm,”</em> or <em class="font-bold">“And?”</em> go a long way. These short prompts are ways of showing that you’re interested and want to learn more. Your prompts will help put the speaker at ease and encourage them to open up and continue sharing their message.</p>`,
    },
    {
      title: 'Summarize and Paraphrase',
      htmlContent: `<p>For your benefit as well as the speaker’s, test your understanding by summarizing and paraphrasing what you’ve heard. While you shouldn’t directly quote the person and parrot their words, you could say something like: <em class="font-bold">“Let’s see if I’m clear about this … ”&nbsp;</em>or <em class="font-bold">“My understanding is …”&nbsp;</em>Paraphrasing shows that you care enough to check your understanding.</p>`,
    },
    {
      title: 'Ask Relevant Questions',
      htmlContent: `<p>By asking relevant questions, you’ll show that you’re paying attention and want to learn more. For example, if your coworker says, <em class="font-bold">“I woke up late and only had 10 minutes to get ready!”</em> you might respond with: <em class="font-bold">“Oh no! Did your alarm go off?”</em> Or, let’s say your boss is giving instructions. You could then ask questions to clarify that you’re on the same page. For instance, you might ask:<em class="font-bold">&nbsp;“Is there a deadline for this project?”</em></p>`,
    },
  ];
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10">
        <h2 className="font-semibold text-3xl">Strategies for Effective Listening</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <p className="text-2xl text-center leading-10 mt-16 font-light mb-16">
          Listening is not a passive process. Listening takes effort and concentration. When done
          right, listening cultivates understanding. And, even more, it shows a speaker that you
          understand and care about their message.
        </p>
        <p className="mb-8">
          In this lesson, we explain what it means to listen actively and how to improve your
          listening skills. Then, we share four tips for displaying active listening.
        </p>
        <div className="video-wrap mt-8 mb-10">
          <video className="rounded-3xl" controls controlsList="nodownload">
            <source
              src={chapter?.course_video_url && chapter?.course_video_url[0]}
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">What Is Active Listening?</h3>
        <p className="mb-8">
          Active listening is paying attention to someone’s words, tone, voice, and expressions.
          Unlike hearing, which is automatic, listening takes work. We need to be engaged, alert,
          and place all our focus on the speaker. It takes determination and concentration. When we
          listen actively, we listen to understand and not just hear.
        </p>
        <MediaBlock mediaImage={ScottPeck}>
          <p className="text-3xl text-center leading-10 font-light lg:w-3/4 mx-auto">
            You cannot truly listen to anyone and do anything else at the same time.
            <br />
            <span className="text-base">M. Scott Peck</span>
          </p>
        </MediaBlock>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">How to Listen Actively</h3>
        <p>So, how do you listen actively? Here are four essential techniques:</p>
        <ul className="list-decimal pl-7 mt-8 mb-10 text-xl">
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Avoid distractions.</span> Our minds tend to wander. To
            improve your focus, put down the distractions and pay attention to the speaker. Don’t
            start thinking about what you need to do after this meeting. Don’t look at your phone,
            computer, or papers on your desk. Give the speaker your undivided attention.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Keep an open mind.</span> We all think and express ourselves
            differently. When someone else is speaking, set aside your opinions, preconceived ideas,
            and beliefs and just listen. If you make judgments, you might jump to the wrong
            conclusions and compromise your ability to understand the message. Instead, consider
            where the speaker is coming from and try to process the information from their point of
            view.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Focus on the speaker, not yourself.</span> Often, especially
            in high-stress situations such as an interview, we’re tempted to start thinking: “What
            will I say next?” But if you’re focusing on your response, then you’re not really
            listening. You’re going to miss part of the message. Instead, clear your mind and
            concentrate only on the other person.
          </li>
          <li className="mb-8 pl-7 marker:font-bold">
            <span className="font-bold">Don’t interrupt.</span> Don’t finish someone else’s
            sentences. Don’t interrupt. Yes, you might have a great, relevant point, but it can
            wait. Be patient. If you steal the conversation, you’ll miss part of the message. Plus,
            it might seem like you value your thoughts more than the other person’s. If you have a
            question, wait until there’s a natural pause before asking it.
          </li>
        </ul>
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          How to Show You’re Listening
        </h3>
        <p className="mb-8">
          Alongside understanding what is said, you also need to show the speaker you understand.
          Flip through the cards below to see how you can show you’re listening.
        </p>
        <TabsBlock tabsData={showYoureListeningTabData} />
        <h3 className="text-2xl text-purple-600 font-bold mt-7 mb-1">
          Are You Listening Actively?
        </h3>
        <CourseQuestionCard
          submittedAnswer={userCourseQuestionResponseContext[
            chapterQuestions[0].course_id
          ]?.chapters[chapter.id]?.questions?.at(0)}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmitAnswer={handleCourseAnswerSubmit}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetakeQuestion={handleQuestionRetake}
          question={chapterQuestions[0]}
        />
        <p className="text-2xl text-center leading-10 font-light mb-10">
          In the next lesson, we're going to explore the different types of questions you can ask
          depending on whether you want to keep the conversation going or cut it off.
        </p>
        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full"
        >
          ASKING CLOSED VERSUS OPEN QUESTIONS
        </button>
      </div>
    </>
  );
};
