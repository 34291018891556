import { Paper, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

import { getUserCourseData } from '../../redux/slice/organization-dashboard/organization-api';
import {
  coursesColumns,
  OrganizationProps,
  UserCourseData,
} from '../../redux/slice/organization-dashboard/organization.types';
import { GenericDataTable } from '../common/GenericDataTable';

export const UserCoursesStats: React.FC<OrganizationProps> = ({ orgnizationId }) => {
  const [filters, setFilters] = useState({ name: '', email: '' });
  const [data, setData] = useState<UserCourseData[]>([]);
  const [loading, setLoading] = useState(true);

  const limit = 100;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = orgnizationId && (await getUserCourseData(orgnizationId, limit));
        if (result) {
          setData(transformData(result?.employeeData ?? []));
        }
      } catch (error) {
        console.error('Error fetching course data:', { error });
      } finally {
        setLoading(false);
      }
    };

    void fetchData();
  }, [orgnizationId, limit]);

  const handleFilterChange = (newFilters: Record<string, string>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  const filteredData = data.filter((user) => {
    return (
      (!filters.name || user.name.toLowerCase().includes(filters.name)) &&
      (!filters.email || user.email.toLowerCase().includes(filters.email))
    );
  });

  const transformData = (data: UserCourseData[]) => {
    return data.map((user) => {
      const courseProgress = user.courses.reduce((acc, course) => {
        acc[course.courseName] = course.progress;
        acc.id = course.id;

        if (course.id === '0b1b086c-3f2e-430c-bff6-75097a79761e') {
          acc['module1PreScore'] = course?.preAssessmentScore;
          acc['module1PostScore'] = course?.postAssessmentScore;
        } else if (course.id === '8302a4a4-f22a-4cdb-8254-c0122317f6c9') {
          acc['module2PreScore'] = course.preAssessmentScore;
          acc['module2PostScore'] = course.postAssessmentScore;
        }
        return acc;
      }, {} as Record<string, number | string>);

      return {
        ...user,
        ...courseProgress,
      };
    });
  };

  return (
    <Paper className="p-6">
      <Typography
        variant="h6"
        fontWeight="bold"
        className="border-purple-900 border-b-2 rounded-lg mb-4 mt-4 text-purple-700"
        gutterBottom
      >
        User Course Data (% completed)
      </Typography>

      <GenericDataTable
        isLoading={loading}
        columns={coursesColumns}
        data={filteredData}
        filterFields={[
          { name: 'name', label: 'Filter by Name' },
          { name: 'email', label: 'Filter by Email' },
        ]}
        onFilterChange={handleFilterChange}
        isCourseTable={true}
      />
    </Paper>
  );
};
