import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import introImg from '../../../../assets/images/courses/jumpstart-communicator/chapter5/intro.jpeg';
import medium1 from '../../../../assets/images/courses/jumpstart-communicator/chapter5/medium1.jpeg';
import medium2 from '../../../../assets/images/courses/jumpstart-communicator/chapter5/medium2.jpeg';
import phoneCall from '../../../../assets/images/courses/jumpstart-communicator/chapter5/phone_call.jpeg';
import { CourseChapter } from '../../../../courses/courses.types';
import {
  course_questions as courseQuestions,
  course_sorting_questions as courseSortingQuestions,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.quiz';
import {
  CourseQuestionAnswer,
  QuestionOption,
  ShortingMatchingBase,
} from '../../../../courses/jumpstart-communicator/jumpstart-communicator.types';
import { useUserCourseQuestionResponseContext } from '../../../../hooks/use-user-course-question-response.hooks';
import {
  dispatchUpdateUserCourseQuestionResponse,
  UserChapterQuestion,
} from '../../../../redux/slice/user-course-question-response';
import { useAppDispatch } from '../../../../redux/store';
import { CourseQuestionCard } from '../../CourseQuestionCard';
import { InfoBlock } from '../../InfoBlock';
import { MediaBlock } from '../../MediaBlock';
import { CourseSortingQuestionBlock } from '../../SortingQuestion/CourseSortingQuestion';
import { TabsBlock } from '../../TabsBlock';
interface JumpStartChapterProps {
  onNextChapter: (nextIndex: number) => void;
  totalPages: number;
  chapter: CourseChapter;
}
type AnswersState = {
  [question: string]: CourseQuestionAnswer;
};

type SortingAnswersState = {
  [questionId: string]: ShortingMatchingBase[];
};
export const JumpStartChapter5: React.FC<JumpStartChapterProps> = ({
  onNextChapter,
  totalPages,
  chapter,
}) => {
  const dispatch = useAppDispatch();
  const [isPageDirty, setIsPageDirty] = useState(true);

  const userCourseQuestionResponseContext = useUserCourseQuestionResponseContext();
  const [, setAnswers] = useState<AnswersState>({});
  const chapterQuestions = courseQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );
  const chapterSortingQuestions = courseSortingQuestions.filter(
    (courseChapter) => courseChapter.chapter_id === chapter.id,
  );

  const handleNextChapterClick = () => {
    onNextChapter(chapter.index + 1);
  };

  const handleCourseAnswerSubmit = async (
    quizAnswers: QuestionOption[],
    isCorrect: boolean,
    questionId?: string,
  ) => {
    setAnswers((prevAnswers) => {
      if (questionId) {
        return {
          ...prevAnswers,
          [questionId]: {
            chapter_id: chapter.id,
            question_id: questionId,
            question: chapterQuestions[0].question,
            is_correct: isCorrect,
            answer_options: quizAnswers,
          },
        };
      } else {
        return { ...prevAnswers };
      }
    });

    const userQuestionAnswer: UserChapterQuestion = {
      question_id: questionId,
      isCorrect: isCorrect,
      question: chapterQuestions.find((x) => x.id === questionId)?.question,
      answers: quizAnswers,
    };
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];
    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [
                ...userAnswerResponse.filter((x) => x.question_id !== questionId),
                userQuestionAnswer,
              ],
              sortingQuestions: [...userSortingAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleQuestionRetake = async (questionId: string) => {
    setAnswers((prevAnswers) => {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [questionId]: _, ...newState } = prevAnswers;
      return newState;
    });
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [chapterQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              questions: [...userAnswerResponse.filter((x) => x.question_id !== questionId)],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };

  const handleSortingExcerciseCompleted = async (sortingAnswers: SortingAnswersState) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[courseSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions || [];
    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [courseSortingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(Object.keys(sortingAnswers)[0]),
                ),
                sortingAnswers,
              ],
              questions: [...userAnswerResponse],
              quiz: null,
            },
          },
        },
      }),
    );
  };
  const handleSortingRetake = async (questionId: string) => {
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[courseSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions || [];

    await dispatch(
      dispatchUpdateUserCourseQuestionResponse({
        [courseSortingQuestions[0].course_id]: {
          chapters: {
            [chapter.id]: {
              sortingQuestions: [
                ...userSortingAnswerResponse.filter(
                  (question) => !Object.keys(question).includes(questionId),
                ),
              ],
              quiz: null,
            },
          },
        },
      }),
    );
    setIsPageDirty(true);
  };
  const strengthMediumTabData = [
    {
      title: 'IN PERSON',
      htmlContent: `<p class="text-base">
        <span class="font-bold">Shows emotion. </span> Whether you’re confessing a mistake, ending a relationship, or resigning from a work position, it’s difficult to deliver bad news. But consider how this information can seem cold and impersonal if it’s shared over a text message or in an email. If your message is emotional—positive or negative—it’s best given in person. It provides the benefit of being able to use tone of voice, facial expressions, and body language to better express yourself and handle the situation delicately.
        <br />
        <br />
        <span class="font-bold">Encourages collaboration. </span>If you need to have an interactive dialogue, an in-person meeting will allow you to check in with your receivers and offer on-the-spot feedback. That is especially helpful if you’re experiencing miscommunication, as you can rely on verbal and nonverbal cues to better clarify your meaning and understand others.
      </p>`,
    },
    {
      title: 'VIDEO',
      htmlContent: `<p class="text-base">
        <span class="font-bold">Allows remote meetings. </span>Video is often considered the next best thing to in-person communication. If you are separated by physical distance, but still need to meet face-to-face, video communication is a great option.
        <br />
        <br />
        <span class="font-bold">Shows emotion. </span>Similar to an in-person meeting, the video medium will allow you to use your body language and other nonverbal cues to add personality and emotion to your communication.
         <br />
        <br />
        <span class="font-bold">Encourages collaboration. </span>Like in-person communication you can use both verbal and nonverbal cues and receive instant responses and feedback, which aids in collaboration.
      </p>`,
    },
    {
      title: 'PHONE',
      htmlContent: `<p class="text-base">
        <span class="font-bold">Expresses urgency. </span>There’s a reason why we call the police during an emergency. Phone calls are great for urgent messages because they’re immediate, require no scheduling, and allow you to receive instant feedback.
        <br />
        <br />
        <span class="font-bold">Shows emotion. </span>While you can’t rely on nonverbal cues during a phone call, you’ll still have the chance to use your tone of voice and inflection. That can help you to add personality and emotion to your message.
        <br />
        <br />
        <span class="font-bold">Encourages one-on-one collaboration. </span>If you need to collaborate one-on-one (or clarify a message), a short phone call will give you the opportunity to have a back-and-forth conversation and get immediate feedback.
      </p>`,
    },
    {
      title: 'WRITTEN',
      htmlContent: `<p class="text-base">
        <span class="font-bold">Stays on track. </span>It’s often easier to organize information when it’s written down. If your message is specific—and doesn’t require a lot of feedback from your receiver—sending a written message will help you stay on track.
        <br />
        <br />
        <span class="font-bold">Provides a record. </span>Written communication has the advantage of being permanent. You can archive written messages and search for them later.
        <br />
        <br />
        <span class="font-bold">Saves time. </span>If you’re in a hurry, written communication allows you to share your message without having to coordinate schedules.
      </p>`,
    },
  ];
  const weaknessMediumTabData = [
    {
      title: 'IN PERSON',
      htmlContent: `<p class="text-base">
        <span class="font-bold">Easily derailed. </span> Have you ever been in a meeting where you wanted to briefly cover a subject, but the conversation kept heading in the wrong direction? Because of their back-and-forth nature, in-person discussions are prone to getting off topic.
        <br />
        <br />
        <span class="font-bold">Time-consuming. </span>Before an in-person meeting can start, you first have to get there. Depending on the distance, travel can be time-consuming. Plus, even if the full contents of the meeting don’t apply to you, you’ll still likely need to stick around until the end.
         <br />
        <br />
        <span class="font-bold">Subjective records. </span>To reference an in-person conversation later, you’ll need to either take notes or set up an audio recording. Both of these options come with their challenges. First, you may miss certain details while writing out notes. With an audio recording, not only could details be muffled, but it’s often time-consuming to search the replay for specific information.
        </p>`,
    },
    {
      title: 'VIDEO',
      htmlContent: `<p class="text-base">
      <span class="font-bold">Easily derailed. </span>Just like an in-person meeting, video calls often get off track.
        <br />
        <br />
        <span class="font-bold">Time-consuming. </span>While video calls will save you time when compared to in-person meetings (since travel is usually not required), this medium still necessitates that you coordinate schedules and block off time on your agenda.
         <br />
        <br />
        <span class="font-bold">Subjective records. </span>Similar to an in-person meeting, it’s difficult to maintain a record and later reference video calls. 
        </p>`,
    },
    {
      title: 'PHONE',
      htmlContent: `<p class="text-base">
      <span class="font-bold">Subjective records. </span>Have you ever ended a phone conversation and thought,
“Wait … what was that second point again?” Similar to in-person and video call communication, there’s no easy way to later reference phone conversations.
        <br />
        <br />
        <span class="font-bold">Time-consuming. </span>While less time-consuming than in-person meetings and video calls, phone calls still include back-and-forth conversations. If your message is specific, this can unnecessarily draw out the time it takes to deliver the information.
         <br />
        <br />
        <span class="font-bold">Limits group collaboration. </span>The more people you collaborate with, the more challenging it is to communicate over the phone. There could be connection delays or you might accidentally speak over one another without the aid of nonverbal cues.
        </p>`,
    },
    {
      title: 'WRITTEN',
      htmlContent: `<p class="text-base">
      <span class="font-bold">Limits collaboration. </span>Because we can’t connect in real time, it’s usually difficult to collaborate when delivering one-sided written messages.
        <br />
        <br />
        <span class="font-bold">Delays response times. </span>If the phrase “Didn’t you get my email?” sounds familiar, you’re not alone. If your receiver’s inbox is cluttered or they do not check their messages—they could miss your urgent communication.
         <br />
        <br />
        <span class="font-bold">Hides emotions. </span>Have you ever written a lengthy email to someone, only to receive a one-word response back such as: “Fine.”? How did you interpret that communication? When we read messages, we tend to view them from our emotional perspective (instead of the other person’s). For this reason, it’s best to keep written messages unemotional and specific.
        </p>`,
    },
  ];

  useEffect(() => {
    const userAnswerResponse =
      userCourseQuestionResponseContext[chapterQuestions[0].course_id]?.chapters[chapter.id]
        ?.questions;
    const userSortingAnswerResponse =
      userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[chapter.id]
        ?.sortingQuestions;
    if (
      Object.keys(userCourseQuestionResponseContext).length &&
      userAnswerResponse?.length &&
      userSortingAnswerResponse?.length
    ) {
      setIsPageDirty(false);
    }
  }, [userCourseQuestionResponseContext]);
  return (
    <>
      <div className="content-wrap lg:border-l lg:pl-8 lg:pt-8 pt-4 pb-10 text-base">
        <h2 className="font-semibold text-3xl">Choosing the Right Communication Medium</h2>
        <p className="text-sm italic">
          Part {chapter.index} of {totalPages}
        </p>
        <MediaBlock cssClass="mb-14" mediaImage={introImg}>
          <div>
            <p className="lg:w-3/4 text-2xl leading-10">
              At the start of every communication, we have an important choice to make:{' '}
              <span className="italic">How will we share our message?</span>
            </p>
          </div>
        </MediaBlock>
        <p className="text-2xl leading-10 text-center font-light mb-10">
          We have an assortment of delivery options—known as mediums—from which to choose. For
          example, we can share our messages in person, through a video call, on the phone, or via
          written text. Each medium has its strengths and weaknesses.
        </p>
        <div className="md:flex items-center mb-7">
          <div className="md:mr-8 max-w-full md:max-w-xs w-full mb-5 md:mb-0">
            <img src={phoneCall} alt="" />
          </div>
          <p className="flex-1">
            For example,
            <br />
            <br />A phone call is the best way to get help fast if you’re in the middle of an
            emergency. But what if your boss uses that same medium to rally off a series of complex
            numbers, statistics, and instructions? Will you remember everything? In that scenario, a
            phone conversation might not be so effective.
          </p>
        </div>
        <p>
          Simply put, the medium you choose can have a significant impact on the success of your
          communication. So, how do you pick the right one? That answer will depend on your
          situation and your message.
          <br />
          <br />
          In this lesson, we’ll explain how to choose the right medium for your situation and
          message by unpacking what a communication medium is and why it matters. We’ll then define
          the strengths and weaknesses of four common mediums, so you can make an informed decision
          on which one will serve you best.
        </p>
        <div className="video-wrap mt-8 mb-12">
          <video className="rounded-3xl" controls controlsList="nodownload">
            <source
              src={chapter?.course_video_url && chapter?.course_video_url[0]}
              type="video/mp4"
            />
          </video>
        </div>
        <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">
          What Is a Communication Medium, and Why Does It Matter?
        </h3>
        <p className="mb-8">
          A communication medium is the method you use to deliver your message. We have many options
          at our disposal, but four of the most common delivery methods include connecting in
          person, via a video call, over the phone, and through written text.
          <br />
          <br />
          When selecting a medium, it’s important to consider the impact of your decision.
          Specifically, by choosing the right medium for your situation and your message, you’re
          more likely to improve the understanding of your communication.
        </p>
        <MediaBlock cssClass="mb-14" mediaImage={medium1}>
          <div>
            <p className="lg:w-3/4 text-2xl leading-10">
              Strengths of In-Person, Video, Phone, and Text-Based Mediums
            </p>
          </div>
        </MediaBlock>
        <p className="mb-10">
          Depending on your situation and your message, each medium has its strengths. Moving from
          most to least personal, expand the rows below by clicking on the (+) sign to learn when
          in-person, video, phone, and written communication will serve you best.
        </p>
        <TabsBlock tabsData={strengthMediumTabData} />
        <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">
          How Will You Send Your Next Message?
        </h3>
        <CourseQuestionCard
          submittedAnswer={userCourseQuestionResponseContext[
            chapterQuestions[0].course_id
          ]?.chapters[chapter.id]?.questions?.at(0)}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmitAnswer={handleCourseAnswerSubmit}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRetakeQuestion={handleQuestionRetake}
          question={chapterQuestions[0]}
        />
        <MediaBlock cssClass="mb-14" mediaImage={medium2}>
          <div>
            <p className="lg:w-3/4 text-2xl leading-10">
              Weaknesses of In-Person, Video, Phone, and Text-Based Mediums
            </p>
          </div>
        </MediaBlock>
        <p className="mb-9">
          Just as each medium has its advantages, it’s also important to consider how selecting the
          wrong medium can negatively impact your communication. Expand the rows below by clicking
          the (+) signs to learn about the weaknesses of in-person, video, phone, and written
          conversations.
        </p>
        <div className="mb-6">
          <TabsBlock tabsData={weaknessMediumTabData} />
        </div>
        <InfoBlock
          title="Choose your medium based on the situation."
          text="Most people have a go-to medium they feel most comfortable using. When choosing your
            medium, make sure your preferences aren’t getting in the way. Remember, your
            communication will be most successful if you make your choice based on the situation and
            message, not necessarily on what feels easiest."
        />
        <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">
          Which Medium Will You Choose?
        </h3>
        <p>
          Take a look at the four scenarios below. Can you match the situation with its correct
          medium?
        </p>
        <DndProvider backend={HTML5Backend}>
          <CourseSortingQuestionBlock
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onRetake={handleSortingRetake}
            question={chapterSortingQuestions[0]}
            submitedCorrectAnswerCount={
              userCourseQuestionResponseContext[chapterSortingQuestions[0].course_id]?.chapters[
                chapter.id
              ]?.sortingQuestions
                ?.filter((x) =>
                  Object.keys(x).filter((key) => key === chapterSortingQuestions[0].id),
                )
                .map(
                  (x) =>
                    x[chapterSortingQuestions[0].id].filter(
                      (x) => x.answer?.id === x.correct_answer?.id,
                    ).length,
                )[0] ?? null
            }
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            submitSortingQuestion={handleSortingExcerciseCompleted}
          />
        </DndProvider>
        <div className="text-xl font-light">
          <h3 className="text-2xl text-purple-600 font-bold mb-5 mt-7">Summary</h3>
          <p>
            Let’s review what we’ve learned. The communication medium can influence how successful
            you are at sharing your message. Each medium has its strengths and weaknesses, which is
            why you should think about both the message and situation before making your choice.
          </p>
          <ul className="list-decimal pl-7 mt-8 mb-10">
            <li className="mb-8 pl-7 marker:font-bold">
              <span className="font-bold">In-person.</span> In-person communication is best when you
              need to share emotions, collaborate with others, or clarify a misunderstanding.
            </li>
            <li className="mb-8 pl-7 marker:font-bold">
              <span className="font-bold">Video call.</span> Video calls are best when you need to
              connect in person, but you're separated by physical distance.
            </li>
            <li className="mb-8 pl-7 marker:font-bold">
              <span className="font-bold">Phone.</span> A phone conversation is best when you need
              to communicate urgent information, or your message requires a limited amount of
              collaboration and emotional expression.
            </li>
            <li className="mb-8 pl-7 marker:font-bold">
              <span className="font-bold">Written.</span> Putting your information in writing is
              most advantageous when your message is simple, your time is limited, and you need a
              record of the communication.
            </li>
          </ul>
        </div>
        <div className="bg-purple-100 text-xl italic p-6 text-center mb-10">
          However you decide to deliver your message, always keep the method in mind. Remember, it’s
          not just what you say, but how you share the information, that matters.
        </div>
        <p className="text-2xl leading-10 text-center font-light mb-10">
          You’ve selected the perfect medium for your message. Next, you'll learn how to avoid
          common communication blocks. Click below for the next lesson.
        </p>
        <button
          disabled={isPageDirty}
          onClick={handleNextChapterClick}
          className="btn-primary w-full"
        >
          HOW TO REMOVE FIVE COMMON COMMUNICATION BARRIERS
        </button>
      </div>
    </>
  );
};
